import React, { useEffect, useState } from "react";
import ImageSlider from "../components/homePage/ImageSlider";
import ".././static/css/homePage/homePage.css";
import SoftwareUpdates from "../components/homePage/SoftwareUpdates";
import Categories from "../components/homePage/Categories";
import DisplayProducts from "../components/homePage/DisplayProducts";
import homeData from ".././data/homePage.json";
// import FreeTrials from '../components/homePage/FreeTrials'
// import ActiveDiscussions from '../components/homePage/ActiveDiscussions'
import HomeFooter from "../components/homePage/HomeFooter";
import { commonAPI } from "../api/commonAPI";
import { useTranslation } from "react-i18next";

let cache = {};

export const HomePage = () => {
  const { t } = useTranslation();
  document.title = `Deskshare - ${t("title")}`;

  const [HomeData, setHomeData] = useState(null);

  useEffect(() => {
    if (cache["homepage"]) {
      setHomeData(cache["homepage"]);
    } else {
      commonAPI("homepage").then((result) => {
        cache["homepage"] = result;
        setHomeData(result);
      });
    }
  }, []);

  if (HomeData) {
    const specialProductNames = [
      "Security Monitor Pro",
      "My Screen Recorder",
      "Auto FTP Manager",
    ];
    const productsToRemove = [
      "IP Camera Viewer",
      "FTP Manager Lite",
      "My Screen Recorder Pro",
    ];

    const specialProducts = [];
    const remainingProducts = Object.keys(HomeData.product_list).reduce(
      (acc, category) => {
        const filteredProducts = HomeData.product_list[category].filter(
          (product) => {
            if (specialProductNames.includes(product.product_name)) {
              specialProducts.push(product);
              return false;
            }
            return !productsToRemove.includes(product.product_name);
          },
        );
        if (filteredProducts.length > 0) {
          acc[category] = filteredProducts;
        }
        return acc;
      },
      {},
    );
    if (specialProducts.length > 1) {
      const temp = specialProducts[1];
      specialProducts[1] = specialProducts[2];
      specialProducts[2] = temp;
    }
    const updatedProductList = {
      "Special Products": specialProducts,
      ...remainingProducts,
    };
    return (
      <>
        <ImageSlider images={HomeData.slider_images} />
        <Categories productId={HomeData.product_list} />
        <div className="home-page">
          <div className="home-page-block">
            <DisplayProducts product={updatedProductList} />
          </div>
          <SoftwareUpdates update={HomeData.product_updates} />
          {/* <FreeTrials /> */}
          {/* Hiding for next update */}
          {/* <ActiveDiscussions discussion={homeData.discussion_list} /> */}
        </div>
        <HomeFooter footer={homeData.footer} />
      </>
    );
  }
};
