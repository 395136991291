import Cookies from "js-cookie";
import axios from "axios";
export const getShoppingCartLoginStatus = () => {
  const isuser_loggedin = [
    ...Object.keys(Cookies.get()).filter(
      (key) =>
        key.startsWith("wordpress") ||
        (key.startsWith("woocommerce") && key !== "woocommerce_test_custom"),
    ),
  ].some((item) => item);

  let is_user = [Cookies.get("woocommerce_test_custom")].some((item) => item);

  if (isuser_loggedin) {
    is_user = true;
  }

  return { isuser_loggedin, is_user };
};

