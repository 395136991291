import axios from "axios";

const IPDetails = async () => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    return response.data;
  } catch (e) {
    return {
      ip: e.message,
    };
  }
};

export default IPDetails;
