export const contactUsAPI = async (data) => {
  const apiData = {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    subject: data.subject,
    message: data.message,
    phone_number: data.contactNumber || "",
  };

  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/contactus-form`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(apiData),
  })
    .then((response) => {
      return response.json().then((data) => {
        if (!response.ok) {
          let err = new Error(data.message);
          err.status = response.status;
          throw err;
        }
        return {
          message: data.message,
          status: response.status,
        };
      });
    })
    .catch((error) => ({
      message: error.message,
      status: error.status,
    }));
};
