import React, { useEffect, useState } from 'react';
import '../static/css/help.css';
import { Link, useParams } from 'react-router-dom';
import { fetchData } from '../api/productPageAPI';
import { useTranslation } from 'react-i18next';
import { AddLangToPath } from '../helper/AddLanguagePath';

const Help = () => {
    const { t } = useTranslation();
    const { productSlug } = useParams();
    const [version, setVersion] = useState(null);
    
    if (version) {
        document.title = `${version[0].product_name}: ${t('whats_new.title')}?`;
    }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData(`${productSlug}/version`).then((result) => setVersion(result));
  }, [productSlug]);

  const changeImagePath = (content) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

        const anchorTags = tempDiv.getElementsByTagName('a');
        for (const anchor of anchorTags) { // Changed to for-of loop
            const href = anchor.getAttribute('href');
            anchor.setAttribute('href', AddLangToPath(`${href}`));
        }

        return tempDiv.innerHTML;
    };

    if (version) {
        return (
            <div className="help-page">
                <div className="help-header">
                    <div className="product-name-title">
                        <Link to={AddLangToPath(`/${productSlug}`)}>
                            <h1>{version[0].product_name}</h1>
                        </Link>
                    </div>
                    <div className="help-heading">
                        <h2>{t('whats_new.title')} {version[0].product_name}</h2>
                    </div>
                </div>
                {
                    version.sort((a, b) => b.version_number - a.version_number).map((ver) => ( // Changed to 'ver' for clarity
                        <div key={ver.id} className='help-article'> {/* Added key prop here */}
                            <div className="version-number">
                                <h3>{t('whats_new.version')} {ver.version_number}</h3>
                            </div>
                            <div className='help-content' dangerouslySetInnerHTML={{ __html: changeImagePath(ver.update_details) }} />
                        </div>
                    ))
                }
            </div>
        );
    }

    return null; // Adding return null for when version is not defined.
}

export default Help;
