import React, { memo } from "react";
import PropTypes from "prop-types";
import "../../static/css/productPage/characteristics.css";
import Features from "./Features";

const Characteristics = ({ featureTable }) => {
  return (
    <div className="characteristics">
      <Features featureTable={featureTable} />
    </div>
  );
};

Characteristics.propTypes = {
  featureTable: PropTypes.array,
};

export default memo(Characteristics);
