import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Fade } from "react-slideshow-image";
import uuid from "react-uuid";
import { Link, useLocation } from "react-router-dom";
import "../../static/css/homePage/imageSlider.css";
import { AddLangToPath } from "../../helper/AddLanguagePath";

const ImageSlider = ({ images }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [initialSlide, setInitialSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const [isBannerClicked, setIsBannerClicked] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  // const navigate = useNavigate();
  const location = useLocation();
  const downloadImage = {
    slider_image: "/images/heroslidersectionimg.webp",
    product_title: "download",
    product_slug: AddLangToPath("/download"),
  };

  if (
    !images.some((image) => image.product_slug === downloadImage.product_slug)
  ) {
    images.unshift(downloadImage);
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const savedIndex = sessionStorage.getItem("bannerIndex");
    if (savedIndex) {
      setInitialSlide(parseInt(savedIndex, 10));
      sessionStorage.removeItem("bannerIndex");
    }
  }, [location.key]);

  const handleBannerClick = useCallback((index) => {
    sessionStorage.setItem("bannerIndex", index);
    setIsBannerClicked(true);
  }, []);

  const handleSlideChange = useCallback(
    (previous, next) => {
      if (!isBannerClicked && !isHovering) {
        setCurrentSlide(next);
      }
      setIsBannerClicked(false);
    },
    [isBannerClicked, isHovering],
  );

  const handleMouseEnter = useCallback((index) => {
    if (index !== 0) {
      setIsHovering(true);
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, []);

  // const slideContent = useMemo(() => (

  // ), [images, handleBannerClick]);

  return (
    <div className="image-slider">
      {windowWidth < 1030 ? (
        <div className="image-box">
          <LazyLoadImage
            src="/images/heroslidersectionimg_mobile.png"
            alt={downloadImage.product_title}
          />
        </div>
      ) : (
        <Fade
          key={initialSlide}
          scale={1.4}
          slidesToScroll={1}
          slidesToShow={1}
          indicators={true}
          arrows={false}
          duration={7000}
          transitionDuration={100}
          defaultIndex={initialSlide}
          pauseOnHover={isHovering}
          onChange={handleSlideChange}
        >
          {images.map((imageContent, index) => (
            <div key={uuid()}>
              <Link
                to={imageContent.product_slug}
                onClick={() => handleBannerClick(index)}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                {/* <LazyLoadImage style={{ objectFit: 'cover', width: '100%', }} src={imageContent.slider_image} alt={imageContent.product_title} /> */}

                <img
                  src={imageContent.slider_image}
                  alt={imageContent.product_title}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </Link>
            </div>
          ))}
        </Fade>
      )}
    </div>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.array.isRequired,
};

export default ImageSlider;
