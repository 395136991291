import React from "react";
import PropTypes from "prop-types";
import "../../static/css/productPage/productTitle.css";
import { Link } from "react-router-dom";

const ProductTitle = ({ title, subTitle, downloadProduct, type, inApp }) => {
  return (
    <div className="product-title">
      <section className="title-section">
        <h1 className="title">{title}</h1>
        <h6 className="sub-title">{subTitle}</h6>
      </section>
      <section className="download-section">
        <Link to={downloadProduct}>
          <svg
            fill="#ffffff"
            height="20px"
            width="20px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 497.89 497.89"
            xmlSpace="preserve"
            stroke="#ffffff"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              stroke="#CCCCCC"
              strokeWidth="15.932352"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <g>
                  <g>
                    <polygon points="227.959,39.869 227.959,242.386 496.549,242.386 496.549,0 "></polygon>
                    <polygon points="1.336,244.746 211.172,244.746 211.172,41.818 1.336,72.798 "></polygon>
                    <polygon points="227.959,458.017 496.549,497.886 496.549,261.535 227.959,261.535 "></polygon>
                    <polygon points="1.336,425.086 211.172,456.066 211.172,261.531 1.336,261.531 "></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          {type === "paid" || inApp ? `Try It Free` : `Get It Free`}
        </Link>
        <p>
          <svg
            stroke="#000000a8"
            fill="#000000a8"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="15px"
            width="15px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="50"
              d="M336 176 225.2 304 176 255.8"
            ></path>
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="50"
              d="M463.1 112.37C373.68 96.33 336.71 84.45 256 48c-80.71 36.45-117.68 48.33-207.1 64.37C32.7 369.13 240.58 457.79 256 464c15.42-6.21 223.3-94.87 207.1-351.63z"
            ></path>
          </svg>
          Secure Download
        </p>
      </section>
    </div>
  );
};

ProductTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  downloadProduct: PropTypes.string.isRequired,
  type: PropTypes.string,
  inApp: PropTypes.bool,
};

export default ProductTitle;

ProductTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
