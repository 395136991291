import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Slide } from "react-slideshow-image";
import uuid from "react-uuid";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ".././helper/zoomImage.css";
import VideoPlayer from "./VideoPlayer";

const ZoomImage = ({ imageIndex, imageData, onClose }) => {
  let properties = {
    prevArrow: (
      <button
        type="button"
        className="nav1 default-nav slider-left-button"
        data-type="prev"
        aria-label="Previous Slide"
      >
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"></path>
        </svg>
      </button>
    ),
    nextArrow: (
      <button
        type="button"
        className="nav2 default-nav slider-right-button"
        data-type="next"
        aria-label="Next Slide"
      >
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path>
        </svg>
      </button>
    ),
  };

  const [imageZoom, setImageZoom] = useState(true);
  const [videoSrc, setVideoSrc] = useState("");

  const closeImage = useCallback(() => {
    setImageZoom(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const playVideo = useCallback((video) => {
    setImageZoom(false);
    setVideoSrc(video);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (imageZoom) {
        if (event.key === "ArrowRight") {
          document.querySelector(".nav2").click();
        } else if (event.key === "ArrowLeft") {
          document.querySelector(".nav1").click();
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [imageZoom]);

  if (imageZoom) {
    return (
      <div
        className="ImagePopupDiv"
        id="ImagePopupDiv"
        style={{ display: "block" }}
      >
        <div className="InnerImageDiv">
          <Slide
            className="zoomImage"
            {...properties}
            autoplay={false}
            transitionDuration={0}
            slidesToScroll={1}
            slidesToShow={1}
            indicators={false}
            defaultIndex={imageIndex}
            infinite={false}
          >
            {imageData.map((imageContent) => {
              return (
                <div className="screenshot" key={uuid()}>
                  {imageContent.video ? (
                    <>
                      <p className="imageText">{imageContent.video_name}</p>
                      <button
                        className="image-button-zoom"
                        onClick={() => playVideo(imageContent.video)}
                      >
                        <LazyLoadImage
                          src={`${process.env.REACT_APP_BACKEND_URL}${imageContent.video_thumbnail}`}
                          alt={imageContent.video_name}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="58"
                          height="58"
                          viewBox="0 0 58 58"
                          fill="none"
                          className="videoplayer-icon"
                        >
                          <circle
                            cx="29"
                            cy="29"
                            r="29"
                            fill="white"
                            fillOpacity="0.6"
                          />
                          <path
                            d="M40.3984 25.536C43.0651 27.0756 43.0651 30.9246 40.3984 32.4642L26.2984 40.6048C23.6318 42.1444 20.2984 40.2199 20.2984 37.1407L20.2984 20.8595C20.2984 17.7803 23.6318 15.8558 26.2984 17.3954L40.3984 25.536Z"
                            fill="black"
                            fillOpacity="0.6"
                          />
                        </svg>
                      </button>
                    </>
                  ) : (
                    <>
                      <p className="imageText">
                        {imageContent.screenshot_detail}
                      </p>
                      <button className="image-button-zoom">
                        <LazyLoadImage
                          src={`${process.env.REACT_APP_BACKEND_URL}${imageContent.screenshot}`}
                          alt={imageContent.screenshot_detail}
                        />
                      </button>
                    </>
                  )}
                </div>
              );
            })}
          </Slide>
          <button className="CloseVideoPopupButton" onClick={closeImage}>
            <FontAwesomeIcon icon={faCircleXmark} />
          </button>
        </div>
      </div>
    );
  } else if (videoSrc) {
    return (
      <VideoPlayer
        video={`${process.env.REACT_APP_BACKEND_URL}${videoSrc}`}
        onClose={closeImage}
      />
    );
  } else {
    return null;
  }
};

ZoomImage.propTypes = {
  imageIndex: PropTypes.number.isRequired,
  imageData: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ZoomImage;
