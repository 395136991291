import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../../static/css/detailedArticle.css';
import '../../static/css/userManual.css';
import { fetchData } from '../../api/productPageAPI';
import { AddLangToPath } from '../../helper/AddLanguagePath';

const UserManual = () => {
  let { product_name_slug } = useParams();
  let { guideSlug } = useParams();
  const [manualData, setManualData] = useState(null);

  const changeImagePath = (content) => {
    const regex = /src=".*?"/g;
    const matches = content.match(regex);
    if (matches) {
      matches.forEach((match) => {
        const newMatch = match.replace(
          'src="',
          `src="${process.env.REACT_APP_BACKEND_URL}`,
        );
        content = content.replace(match, newMatch);
      });
    }

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

        // Remove <p> tags that only contain `&nbsp;`
        const pTags = tempDiv.getElementsByTagName('p');
        for (const pTag of pTags) {
            if (pTag.innerHTML.trim() === '&nbsp;') {
                pTag.parentNode.removeChild(pTag);
            }
        }

    const anchorTags = tempDiv.getElementsByTagName("a");
    for (const anchor of anchorTags) {
      const href = anchor.getAttribute("href");
      anchor.setAttribute("href", AddLangToPath(`${href}`));
    }

        return tempDiv.innerHTML;
    };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData(`${product_name_slug}/user_manual`).then((result) =>
      setManualData(result),
    );
  }, [product_name_slug]);

    if (manualData) {
        return (
            <div className="user-manual-container">
                <div className='detailed-guide'>
                    <div className="user-guide">
                        <h5 className='article-names'>User Guide</h5>
                        {
                            manualData.map((manual) => {
                                return (
                                    manual.is_manual_visible ? (
                                        <Link key={manual.id} to={AddLangToPath(`/help/${manual.product_name_slug}/${manual.user_manual_slug}`)}>
                                            <p style={manual.user_manual_slug === guideSlug ? { backgroundColor: '#91bbfe' } : { color: 'black' }}>{manual.user_manual_title}</p>
                                        </Link>
                                    ) : null
                                );
                            })
                        }
                    </div>
                    <div className="detailed-manual-content">
                        <h1>
                            <Link to={AddLangToPath(`/${manualData[0].product_slug}`)}>
                                {manualData[0].product_name}
                            </Link>
                        </h1>
                        <hr />
                        {
                            (() => {
                                const manual = manualData.find(manual => manual.user_manual_slug === guideSlug);
                                if (manual) {
                                    document.title = `${manual.product_name}: ${manual.user_manual_title}`;
                                    return (
                                        <>
                                            <h3>{manual.user_manual_title}</h3>
                                            <div dangerouslySetInnerHTML={{ __html: changeImagePath(manual.user_manual_content) }}></div>
                                        </>
                                    );
                                } else {
                                    return <h3>Manual not found</h3>;
                                }
                            })()
                        }
                    </div>
                </div>
            </div>
        );
    }

    return null; // Return null or a loading indicator while data is being fetched
};

export default UserManual;
