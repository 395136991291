import "../static/css/securityMonitorProPurchasePage.css";
import * as React from "react";
import { Link } from "react-router-dom";
import { fetchData } from "../api/productPageAPI";
import { AddLangToPath } from "../helper/AddLanguagePath";

const getMappedLang = (lang) => {
  const LangMapper = {
    en: "eng",
    de: "ge",
    fr: "fr",
    es: "sp",
    pt: "po",
    it: "it",
  };
  return LangMapper[lang];
};

const lang = localStorage.getItem("lang") || "en";

const plans = [
  {
    name: "Security Monitor Pro - 2 Camera License",
    price: 89.95,
    link: `https://store.deskshare.com/AddProduct.aspx?ProductId1=194&Lang=${getMappedLang(
      lang,
    )}`,
  },
  {
    name: "Security Monitor Pro - 4 Camera License",
    price: 169.95,
    link: `https://store.deskshare.com/AddProduct.aspx?ProductId1=195&Lang=${getMappedLang(
      lang,
    )}`,
  },
  {
    name: "Security Monitor Pro - 8 Camera License",
    price: 299.95,
    link: `https://store.deskshare.com/AddProduct.aspx?ProductId1=196&Lang=${getMappedLang(
      lang,
    )}`,
  },
  {
    name: "Security Monitor Pro - 16 Camera License",
    price: 489.95,
    link: `https://store.deskshare.com/AddProduct.aspx?ProductId1=197&Lang=${getMappedLang(
      lang,
    )}`,
  },
  {
    name: "Security Monitor Pro - 32 Camera License",
    price: 799.95,
    link: `https://store.deskshare.com/AddProduct.aspx?ProductId1=198&Lang=${getMappedLang(
      lang,
    )}`,
  },
];

const PurchasePage = () => {
  let productSlug = "video-surveillance-software";
  const [productPrice, setProductPrice] = React.useState(); 
  const localStorageValues = JSON.parse(localStorage.getItem("currencyData"));
  const { currency_code, currency_symbol, exchange_rate } =
    localStorageValues || {};

  function getDeviceType(userAgent) {
    const mobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|Touch/i.test(
        userAgent,
      );

    return mobile;
  }


  React.useEffect(() => {
    fetchData(`${productSlug}/cart`).then((result) => {
      const transformedResult = result.reduce((acc, item) => {
        if (!acc.product) {
          acc = {
            product: item.product,
            product_name: item.product_name,
            cart_type: item.cart_type,
            cart: [],
          };
        }

        acc.cart.push({
          id: item.id,
          cart_catagory: item.cart_catagory,
          catagory_price: item.catagory_price,
          catagory_id: item.catagory_id,
        });

        return acc;
      }, {});

      setProductPrice(transformedResult);
    });
  }, [productSlug]);

  const generateQueryString = (categoryId, count) => {
    return `${process.env.REACT_APP_SHOPPING_CART_URL}/?add-to-cart=${Array(
      count,
    )
      .fill(categoryId)
      .join(",")}`;
  };

  return (
    <div className="purchase-page">
      <section className="purchase-title-block">
        <h3 className="purchase-title">
          Pick your plan for {productPrice?.product_name}
        </h3>
      </section>

      <section className="sm_plan-container">
        <div className="sm_plan">
          <div className="sm_plan-header">
            <h3 className="sm_plan-title">Select Camera License</h3>
            <p>
              Please choose the Camera Licenses based on the maximum number of
              cameras you will be using.
            </p>
          </div>
          <div className="sm_plan-content">
            {plans.map((plan, index) => (
              <React.Fragment key={plan.name}>
                <div className="sm_plan-item">
                  <p>{plan.name}</p>
                  <div className="sm_pricing-block">
                    <p className="sm_pricing">
                      {currency_symbol} {plan.price * exchange_rate}{" "}
                      {currency_code}
                    </p>
                    <a
                      className="sm_purchase-button"
                      type="button"
                      href={plan.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M17 18C15.89 18 15 18.89 15 20C15 20.5304 15.2107 21.0391 15.5858 21.4142C15.9609 21.7893 16.4696 22 17 22C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20C19 19.4696 18.7893 18.9609 18.4142 18.5858C18.0391 18.2107 17.5304 18 17 18ZM1 2V4H3L6.6 11.59L5.24 14.04C5.09 14.32 5 14.65 5 15C5 15.5304 5.21071 16.0391 5.58579 16.4142C5.96086 16.7893 6.46957 17 7 17H19V15H7.42C7.3537 15 7.29011 14.9737 7.24322 14.9268C7.19634 14.8799 7.17 14.8163 7.17 14.75C7.17 14.7 7.18 14.66 7.2 14.63L8.1 13H15.55C16.3 13 16.96 12.58 17.3 11.97L20.88 5.5C20.95 5.34 21 5.17 21 5C21 4.73478 20.8946 4.48043 20.7071 4.29289C20.5196 4.10536 20.2652 4 20 4H5.21L4.27 2M7 18C5.89 18 5 18.89 5 20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22C7.53043 22 8.03914 21.7893 8.41421 21.4142C8.78929 21.0391 9 20.5304 9 20C9 19.4696 8.78929 18.9609 8.41421 18.5858C8.03914 18.2107 7.53043 18 7 18Z"
                          fill="white"
                        ></path>
                      </svg>
                      Buy Now
                    </a>
                  </div>
                </div>
                {index < plans.length - 1 && (
                  <div className="sm_separator"></div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </section>

      <section className="purchase-content">
        <p className="satisfaction">Satisfaction Guaranteed</p>
        <p className="satisfaction-text">
          <img src="/images/guaranteed.png" alt="guaranteed_img" />
          We guarantee 100% satisfaction for all of our products.
        </p>
        <p className="immediate-content">Immediate Delivery</p>
        <p className="immediate-text">
          Receive your license key via email immediately after purchase.
        </p>
        <p className="immediate-text">
          {!getDeviceType(navigator.userAgent) ? (
            <Link to={AddLangToPath("/download")}>Download</Link>
          ) : (
            "Download"
          )}{" "}
          and install the trial version, then enter the license key to activate
          it to the full version. If you purchase from within the application,
          it will instantly activate to the full version.
        </p>
        <p className="lifetime-support">Lifetime Technical Support</p>
        <p className="lifetime-text">
          Enjoy free technical support via email and chat for as long as you own
          the product. For additional support options, please see our{" "}
          <Link to={AddLangToPath("/articles")}>Online Resources</Link> or
          consider our{" "}
          <Link to={AddLangToPath("/priority-support")}>
            Annual Priority Support
          </Link>{" "}
          plan for comprehensive phone support.
        </p>
        <p className="upgrade-policy">
          Upgrade Policy for {productPrice?.product_name}
        </p>
        <p className="upgrade-text">
          All program updates are FREE as long as you maintain your{" "}
          {productPrice?.cart_type === "dropdown"
            ? "Annual Subscription"
            : "subscription"}
          .{" "}
          {productPrice?.cart_type === "dropdown" ? (
            <>
              Lifetime license holders will receive free updates for two years
              from the date of purchase. After two years, upgrades will be
              available at a discounted price.
            </>
          ) : null}
        </p>
      </section>
    </div>
  );
};

export default PurchasePage;
