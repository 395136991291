import axios from "axios";

const LocationDetails = async (latitude, longitude) => {
  try {
    const locationData = await axios.get(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`,
    );
    return locationData.data;
  } catch (e) {
    return {
      location: e.message,
    };
  }
};

export default LocationDetails;
