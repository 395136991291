import React, { useEffect, useState } from "react";
import "../../static/css/detailedArticle.css";
import { useParams, Link } from "react-router-dom";
import { commonAPI } from "../../api/commonAPI";
import { useTranslation } from "react-i18next";
import { AddLangToPath } from "../../helper/AddLanguagePath";

const DetailedArticle = () => {
  const { t } = useTranslation();
  let { detailedArticleSlug } = useParams();
  let { articleSlug } = useParams();
  const [articleData, setArticleData] = useState(null);

  const changeImagePath = (content) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

        const imgTags = tempDiv.getElementsByTagName('img');
        for (const img of imgTags) {
            let src = img.getAttribute('src');
            img.setAttribute('alt', 'Article Image');
            src = `${process.env.REACT_APP_BACKEND_URL}${src}`;
            img.setAttribute('src', src);
        }

    const anchorTags = tempDiv.getElementsByTagName("a");
    for (const anchor of anchorTags) {
      let href = anchor.getAttribute("href");
      if (href?.endsWith(".mp4") || href.end) {
        href = `${process.env.REACT_APP_BACKEND_URL}${href}`;
      }
      anchor.setAttribute("href", AddLangToPath(`${href}`));
    }

    return tempDiv.innerHTML;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    commonAPI(`allarticles/${articleSlug}`)
      .then((result) => setArticleData(result[articleSlug]))
      .catch(() => {
        window.location.href = "/";
      });
  }, [articleSlug]);

  if (articleData) {
    document.title = `${t("resources.articles.title")} - ${articleData.article[detailedArticleSlug].article_title}`;
    return (
      <div className="detailed-article">
        <div className="more-article">
          <h5 className="article-names">
            {t("resources.articles.detail_side_bar")}
          </h5>
          {Object.keys(articleData.article).map((articleName) => {
            return (
              <a
                style={
                  articleName === detailedArticleSlug
                    ? { backgroundColor: "#91bbfe" }
                    : { color: "black" }
                }
                key={articleName.id}
                href={AddLangToPath(`/${articleSlug}/${articleName}`)}
              >
                <p>{articleData.article[articleName].article_title}</p>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="chevron-right"
                  class="svg-inline--fa fa-chevron-right "
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="currentColor"
                    d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                  ></path>
                </svg>
              </a>
            );
          })}
        </div>
        <div className="detailed-article-content">
          <div>
            <h1 className="article-product-name">
              <Link to={AddLangToPath(`/${articleData.product_slug}`)}>
                {articleData.product_name}
              </Link>
            </h1>
          </div>
          <article>
            <h3>{articleData.article[detailedArticleSlug].article_title}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: changeImagePath(
                  articleData.article[detailedArticleSlug].article_content,
                ),
              }}
            ></div>
          </article>
        </div>
      </div>
    );
  }
};

export default DetailedArticle;
