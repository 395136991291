import React, { useEffect, useState } from "react";
import IPDetails from "../helper/IPDetails";
import LocationDetails from "../helper/LocationDetails";
import {
  isBrowser,
  browserName,
  osName,
  osVersion,
  deviceType,
  isDesktop,
} from "react-device-detect";
import UAParser from "ua-parser-js";
import moment from "moment";
import ".././static/css/myPCInfo.css";
import { useTranslation } from "react-i18next";

const MyPCInfo = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [ipDetails, setIpDetails] = useState({});
  const [iframe, setIframe] = useState("");
  const [locationDetails, setLocationDetails] = useState({});
  const parser = new UAParser();
  const result = parser.getResult();
  const isProxy = false; // Need to implement this from server side
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localTime = moment().format("ddd MMM D YYYY HH:mm:ss [GMT]ZZ");
  const lang = window.localStorage.getItem("lang");

  useEffect(() => {
    IPDetails().then((data) => {
      setIpDetails(data);
      const iframe = `<iframe src="https://maps.google.com/maps?q=${data.latitude},${data.longitude}&z=15&output=embed&hl=${lang}" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`;
      setIframe(iframe);

      if (data.latitude && data.longitude) {
        LocationDetails(data.latitude, data.longitude).then((locationData) => {
          setLocationDetails(locationData);
          setLoading(false);
        });
      }
    });

    window.scrollTo(0, 0);
  }, [lang]);

  if (loading) {
    return <div>{t("my_pc_info.loading")}</div>;
  }

  return (
    <div className="mypcinfo">
      <h1>
        {t("my_pc_info.title")}: {ipDetails.ip}
      </h1>
      <div className="ip-map" dangerouslySetInnerHTML={{ __html: iframe }} />
      <div className="table-container">
        <div className="info-table">
          <table className="ip-table">
            <tbody>
              <tr>
                <th colSpan="2">{t("my_pc_info.ip_info.title")}</th>
              </tr>
              <tr>
                <td>{t("my_pc_info.ip_info.ip")}:</td>
                <td>{ipDetails.ip}</td>
              </tr>
              <tr>
                <td>ISP:</td>
                <td>{ipDetails.org}</td>
              </tr>
              <tr>
                <td>{t("my_pc_info.ip_info.proxy")}:</td>
                <td>{isProxy ? "Yes" : "No"}</td>
              </tr>
            </tbody>
          </table>
          <table className="ip-table">
            <tbody>
              <tr>
                <th colSpan="2">{t("my_pc_info.browser_info.title")}</th>
              </tr>
              <tr>
                <td>{t("my_pc_info.browser_info.user_agent")}:</td>
                <td>{navigator.userAgent}</td>
              </tr>
              <tr>
                <td>{t("my_pc_info.browser_info.device")}:</td>
                <td>{isDesktop ? "Desktop" : deviceType}</td>
              </tr>
              <tr>
                <td>{t("my_pc_info.browser_info.os")}:</td>
                <td>
                  {osName}-{osVersion}
                </td>
              </tr>
              <tr>
                <td>{t("my_pc_info.browser_info.architecture")}:</td>
                <td>{result.cpu.architecture}</td>
              </tr>
              <tr>
                <td>{t("my_pc_info.browser_info.browser")}:</td>
                <td>{isBrowser ? browserName : "Unknown"}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="info-table">
          <table className="ip-table">
            <tbody>
              <tr>
                <th colSpan="2">{t("my_pc_info.location_info.title")}</th>
              </tr>
              <tr>
                <td>{t("my_pc_info.location_info.city")}:</td>
                <td>{ipDetails.city}</td>
              </tr>
              <tr>
                <td>{t("my_pc_info.location_info.state")}:</td>
                <td>{locationDetails.address.state}</td>
              </tr>
              <tr>
                <td>{t("my_pc_info.location_info.country")}:</td>
                <td>{ipDetails.country_name}</td>
              </tr>
              <tr>
                <td>{t("my_pc_info.location_info.continent")}:</td>
                <td>{ipDetails.continent_code}</td>
              </tr>
              <tr>
                <td>{t("my_pc_info.location_info.zip")}:</td>
                <td>{locationDetails.address.postcode}</td>
              </tr>
              <tr>
                <td>{t("my_pc_info.location_info.local_time")}:</td>
                <td>{`${localTime} (${timeZone})`}</td>
              </tr>
              <tr>
                <td>{t("my_pc_info.location_info.timezone")}:</td>
                <td>{ipDetails.timezone}</td>
              </tr>
              <tr>
                <td>{t("my_pc_info.location_info.longitude")}L:</td>
                <td>{ipDetails.longitude}</td>
              </tr>
              <tr>
                <td>{t("my_pc_info.location_info.latitude")}:</td>
                <td>{ipDetails.latitude}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="about-ip">
        <h2>{t("my_pc_info.question1.title")}</h2>
        <p>{t("my_pc_info.question1.answer1")}</p>
        <p>{t("my_pc_info.question1.answer2")}</p>
        <p>{t("my_pc_info.question1.answer3")}</p>
        <p>{t("my_pc_info.question1.answer4")}</p>
        <p>{t("my_pc_info.question1.answer5")}</p>
        <h2>{t("my_pc_info.question2.title")}</h2>
        <p>{t("my_pc_info.question2.answer1")}</p>
        <p>{t("my_pc_info.question2.answer2")}</p>
        <p>{t("my_pc_info.question2.answer3")}</p>
      </div>
    </div>
  );
};

export default MyPCInfo;
