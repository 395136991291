import React, { memo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PropTypes from "prop-types";
import uuid from "react-uuid";
import "../../static/css/productPage/features.css";
import { useTranslation } from "react-i18next";

const FeatureItem = memo(({ features }) => (

    <div className="main-features">
        {
            features.map((feature, index) => (
                <div className="feature" key={uuid()}>
                    <div className={`feature-body ${index % 2 !== 0 && "flex-body-reverse"}`}>
                        <div className="feature-body-image">
                            <LazyLoadImage src={feature.feature_image} alt={feature.feature_title} />
                        </div>
                        <div className={`feature-body-list ${index % 2 === 0 && "feature-body-list-reverse"}`}>
                            <div className="feature-head">
                                {feature.feature_title}
                            </div>
                            <ul>
                                {feature.features.map((featureContent) => (
                                    featureContent.feature.includes("|") ? (
                                        <ul className='feature-inner-list' key={uuid()}>
                                            {featureContent.feature.split("|").map((item) => (
                                                <li key={uuid()}>{item}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <li key={uuid()}>{featureContent.feature}</li>
                                    )
                                ))}
                            </ul>
                        </div>
                    </div>

                </div>
            ))
        }

    </div>
));

const Features = ({ featureTable }) => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="feature-title" id="features">
        {t("product_page.glance")}
      </h1>
      {featureTable ? (
        <FeatureItem key={uuid()} features={featureTable} />
      ) : null}
    </>
  );
};

FeatureItem.propTypes = {
    features: PropTypes.arrayOf(
        PropTypes.shape({
            feature_image: PropTypes.string,
            feature_title: PropTypes.string,
            features: PropTypes.arrayOf(
                PropTypes.shape({
                    feature: PropTypes.string
                })
            )
        })
    ).isRequired,
    featureItem: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        image: PropTypes.string,
        altTxt: PropTypes.string,
        featuresList: PropTypes.arrayOf(PropTypes.string)
    })
};

Features.propTypes = {
    featureTable: PropTypes.array
};

export default Features;
