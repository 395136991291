import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../static/css/productPage/productVersion.css';
import uuid from 'react-uuid';

const ProductVersion = ({ version, newVersion, os, server }) => {
  const { t } = useTranslation();
  const [osVersion, setOsVersion] = useState("Unknown OS");

  useEffect(() => {
    if (navigator.userAgentData) {
      navigator.userAgentData
        .getHighEntropyValues(["platformVersion"])
        .then((ua) => {
          let version = parseFloat(ua.platformVersion);
          if (version === 0) {
            setOsVersion("8");
          } else if (version >= 1 && version <= 10) {
            setOsVersion("10");
          } else if (version >= 13) {
            setOsVersion("11");
          } else {
            setOsVersion("Unknown OS");
          }
        })
        .catch((error) => {
          console.error(error);
          setOsVersion("Unknown OS");
        });
    } else {
      setOsVersion("Unknown OS");
    }
  }, []);

    return (
        <div className='version'>
            <div className='version'>
                <div className="title-text">{t('product_page.features.version')}:</div>
                <div className="content-text">{version}</div>
                <Link to={`whatsnew/${newVersion}`}>{t('product_page.features.new')}?</Link>
            </div>
            <div className="version">
                <span className="title-text">{t('product_page.features.platform')}:</span>
                <div className="content-text">
                    <span>
                        {os?.split(' ')?.map((word) =>
                            word === osVersion
                                ? <strong key={uuid()}>{` ${word}`}</strong>
                                : <span key={uuid()}>{` ${word}`}</span>
                        )}
                    </span>
                    <span>Windows {server}</span>
                </div>
            </div>
        </div>
    )
}
ProductVersion.propTypes = {
    version: PropTypes.string.isRequired,
    newVersion: PropTypes.string.isRequired,
    os: PropTypes.string.isRequired,
    server: PropTypes.string.isRequired,
};

export default ProductVersion
