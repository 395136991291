import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../static/css/supportPages/technicalSupport.css";
import { productSupportAPI } from "../../api/productSupportAPI";
import { useTranslation } from "react-i18next";
import { AddLangToPath } from "../../helper/AddLanguagePath";
import ThankYou from "../../components/ThankYou";

const Button = ({ name, selectedButton, handleButtonClick }) => (
  <button
    style={
      selectedButton === name
        ? { backgroundColor: "rgb(0, 137, 191)", color: "white" }
        : null
    }
    onClick={() => handleButtonClick(name)}
  >
    {name}
  </button>
);

const Select = ({
  options,
  handleTechnicalSupportSelectedProduct,
  isTechnicalSupportProductSelected,
}) => (
  <select
    onChange={handleTechnicalSupportSelectedProduct}
    style={{ color: isTechnicalSupportProductSelected ? "black" : "#8a7575" }}
    required
  >
    <option value="" hidden="">
      Select a Product
    </option>
    {options.map((option) => (
      <option key={uuid()} value={option}>
        {option}
      </option>
    ))}
  </select>
);

const TechnicalSupport = () => {
  const { t } = useTranslation();
  document.title = t("support_pages.technical_support.page_title");
  const [
    isTechnicalSupportProductSelected,
    setIsTechnicalSupportProductSelected,
  ] = useState(false);
  const [isTechnicalSupportIssueSelected, setIsTechnicalSupportIssueSelected] =
    useState(false);
  const [technicalSupportSelectedProduct, setTechnicalSupportSelectedProduct] =
    useState("");
  const [technicalSupportSelectedIssue, setTechnicalSupportSelectedIssue] =
    useState("");
  const [version, setVersion] = useState("");
  const [text, setText] = useState("");
  const [selectedButton, setSelectedButton] = useState(
    t("support_pages.technical_support.buttons.button1"),
  );
  const [openQuestions, setOpenQuestions] = useState(false);
  const [question, setQuestion] = useState("");
  const [showButtons, setShowButtons] = useState(true);
  const [phone, setPhone] = useState();
  const [openMessage, setOpenMessage] = useState(false);
  const [apiResponse, setApiResponse] = useState();
  const [showResponse, setShowResponse] = useState(false);
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: phone,
    technicalSupportSelectedProduct: technicalSupportSelectedProduct,
    version: version,
    message: text,
  });
  const [validationMessages, setValidationMessages] = useState({
    firstName: "",
    lastName: "",
    email: "",
    technicalSupportSelectedProduct: technicalSupportSelectedProduct,
    version: version,
    message: text,
  });
  const [accept, setAccept] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (apiResponse !== undefined) {
      setShowResponse(true);
      setTimeout(() => {
        setShowResponse(false);
        navigate("/");
      }, 10000);
    }
  }, [apiResponse, navigate]);

  const buttons = [
    t("support_pages.technical_support.buttons.button1"),
    t("support_pages.technical_support.buttons.button2"),
    t("support_pages.technical_support.buttons.button3"),
  ];
  const products = [
    "Auto FTP Manager",
    "Call For Help",
    "Dictation Pro",
    "Document Translator",
    "FTP Manager Lite",
    "FTP Manager Pro",
    "IP Camera Monitor",
    "IP Camera Viewer",
    "IP Phone Camera",
    "Magnifier - Vision Enhancer",
    "Medical Reminder",
    "My Screen Recorder",
    "My Screen Recorder Pro",
    "Paper Scanner Pro",
    "Mobile to PC File Transfer",
    "Portable Scandroid",
    "Screen Cast",
    "Security Monitor Pro",
    "Senior Safety Phone",
    "Text Speaker Live",
    "WebCam Monitor",
  ];
  const QUESTIONS = {
    PURCHASED: t("support_pages.technical_support.questions.purchased"),
    PURCHASE: t("support_pages.technical_support.questions.purchase"),
    REQUIRED: t("support_pages.technical_support.questions.required"),
    isTechnicalSupportProductSelected: PropTypes.bool.isRequired,
  };

  const issues = [
    t("support_pages.technical_support.issues.issue1"),
    t("support_pages.technical_support.issues.issue2"),
    t("support_pages.technical_support.issues.issue3"),
    t("support_pages.technical_support.issues.issue4"),
    t("support_pages.technical_support.issues.issue5"),
  ];

  const handleTechnicalSupportSelectedProduct = (event) => {
    setIsTechnicalSupportProductSelected(event.target.value !== "");
    setTechnicalSupportSelectedProduct(event.target.value);
    setFormState((preFormState) => ({
      ...preFormState,
      technicalSupportSelectedProduct:
        event.target.value !== "" ? event.target.value : "",
    }));

    setValidationMessages((prevValidationState) => ({
      ...prevValidationState,
      technicalSupportSelectedProduct:
        event.target.value === ""
          ? t("support_pages.technical_support.validation_messages.product")
          : "",
    }));
  };

  const handleTechnicalSupportSelectedIssue = (event) => {
    setIsTechnicalSupportIssueSelected(event.target.value !== "");
    setTechnicalSupportSelectedIssue(event.target.value);
    setFormState({
      ...formState,
      technicalSupportSelectedIssue:
        event.target.value !== "" ? event.target.value : "",
    });
  };

  const handleVersionChange = (event) => {
    let inputValue = event.target.value.replace(/\D/g, "");
    if (inputValue.length > 1) {
      inputValue = inputValue.slice(0, 1) + "." + inputValue.slice(1);
    }
    setVersion(inputValue);
    setFormState({
      ...formState,
      version: inputValue,
    });

    setValidationMessages((prevValidationState) => ({
      ...prevValidationState,
      version:
        event.target.value === ""
          ? t(
              "support_pages.technical_support.validation_messages.version_number",
            )
          : "",
    }));
  };

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const handleQuestions = () => {
    setOpenQuestions(true);
    setQuestion(QUESTIONS.PURCHASED);
    setShowButtons(true);
  };

  const changeQuestion = () => {
    if (question === QUESTIONS.PURCHASED) {
      setQuestion(QUESTIONS.PURCHASE);
    } else if (question === QUESTIONS.PURCHASE) {
      setQuestion(QUESTIONS.REQUIRED);
      setShowButtons(false);
    }
  };

  const answerQuestion = () => {
    if (question === QUESTIONS.PURCHASED) {
      window.open(
        `${process.env.REACT_APP_SHOPPING_CART_URL}/login/`,
        "_blank",
      );
    } else {
      window.location.href = "/priority-support";
    }
  };

  const handleCount = (event) => {
    setText(event.target.value);
    setFormState({
      ...formState,
      message: event.target.value,
    });

    setValidationMessages((prevValidationState) => ({
      ...prevValidationState,
      message:
        event.target.value === ""
          ? t("support_pages.technical_support.validation_messages.message")
          : "",
    }));
  };

  const handleMessage = () => {
    setOpenMessage(!openMessage);
  };

  const handleSupportFormInputs = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setValidationMessages((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    // Validate email on blur
    if (name === "email") {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(value)) {
        setValidationMessages((prevState) => ({
          ...prevState,
          email: t("support_pages.technical_support.validation_messages.email"),
        }));
      }
    }
  };

  const handleSupportForm = async (event) => {
    setApiResponse(undefined);
    event.preventDefault();

    if (!isFormValid()) {
      setValidationMessages(getValidationMessages());
      return;
    }

    const response = await productSupportAPI(formState);
    setApiResponse(response);
    if (response.status === 200) {
      resetForm();
    }
  };

  const isFormValid = () => {
    const formStateCopy = { ...formState };
    delete formStateCopy.contactNumber;
    const isFormFilled = Object.values(formStateCopy).every(
      (field) => field !== "",
    );

    if (
      formState.email &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formState.email)
    ) {
      setValidationMessages((prevState) => ({
        ...prevState,
        email: t("support_pages.technical_support.validation_messages.email"),
      }));
      return false;
    }

    return isFormFilled;
  };

  const getValidationMessages = () => {
    return {
      firstName:
        formState.firstName === ""
          ? t("support_pages.technical_support.validation_messages.first_name")
          : "",
      lastName:
        formState.lastName === ""
          ? t("support_pages.technical_support.validation_messages.last_name")
          : "",
      email:
        formState.email === ""
          ? t("support_pages.technical_support.validation_messages.email")
          : "",
      technicalSupportSelectedProduct:
        formState.technicalSupportSelectedProduct === ""
          ? t("support_pages.technical_support.validation_messages.product")
          : "",
      version:
        formState.version === ""
          ? t(
              "support_pages.technical_support.validation_messages.version_number"
            )
          : "",
      message:
        formState.message === ""
          ? t("support_pages.technical_support.validation_messages.message")
          : "",
    };
  };

  const resetForm = () => {
    setFormState((prevState) => ({
      ...prevState,
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    }));
    setPhone("");
    setText("");
    setValidationMessages({
      firstName: "",
      lastName: "",
      email: "",
      technicalSupportSelectedProduct: "",
      version: "",
      text: "",
    });
    setAccept(true);
    setOpenMessage(false);
  };

  const handlePhoneChange = (phone) => {
    setPhone(phone);
    setFormState({
      ...formState,
      contactNumber: phone,
    });
  };

  return (
    <>
      {accept && showResponse && apiResponse && apiResponse.status === 200 ? (
        <ThankYou applicationName={technicalSupportSelectedProduct} />
      ) : (
        <>
          <div className="technical-header">
            <h1>{t("support_pages.technical_support.title")}</h1>
            <p>{t("support_pages.technical_support.description")}</p>
          </div>
          <div className="support-form">
            <p>{t("support_pages.technical_support.let_us_know")}</p>
            <div className="support-type">
              {buttons.map((name) => (
                <Button
                  key={name}
                  name={name}
                  selectedButton={selectedButton}
                  handleButtonClick={handleButtonClick}
                />
              ))}
            </div>
            {selectedButton ===
              t("support_pages.technical_support.buttons.button1") ||
            selectedButton ===
              t("support_pages.technical_support.buttons.button2") ? (
              <>
                <div className="form-content">
                  <label htmlFor="selectApp">
                    {t("support_pages.technical_support.select_app")}
                  </label>
                  <div className="help">
                    <select
                      name="selectApp"
                      id="selectApp"
                      onChange={handleTechnicalSupportSelectedProduct}
                      value={technicalSupportSelectedProduct}
                      style={{
                        color: isTechnicalSupportProductSelected
                          ? "black"
                          : "#8a7575",
                      }}
                      required
                    >
                      <option value="" hidden="">
                        {t("support_pages.technical_support.product_choice")}
                      </option>
                      {products.map((product) => (
                        <option key={product} value={product}>
                          {product}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      placeholder={t(
                        "support_pages.technical_support.version_number",
                      )}
                      value={version}
                      onChange={handleVersionChange}
                      maxLength="5"
                    />
                  </div>
                </div>
                <div className="form-content">
                  <label htmlFor="issues">
                    {t("support_pages.technical_support.tell_more")}
                  </label>
                  <select
                    name="issues"
                    id="issues"
                    onChange={handleTechnicalSupportSelectedIssue}
                    style={{
                      color: isTechnicalSupportIssueSelected
                        ? "black"
                        : "#8a7575",
                    }}
                    required
                  >
                    <option value="" hidden="">
                      {t("support_pages.technical_support.select_issue")}
                    </option>
                    {issues.map((issue) => (
                      <option key={issue} value={issue}>
                        {issue}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-content">
                  <label htmlFor="solution">
                    {t("support_pages.technical_support.solution")}
                  </label>
                  <input
                    type="text"
                    name=""
                    id="solution"
                    disabled
                    value={t(
                      "support_pages.technical_support.display_solution",
                    )}
                  />
                </div>
              </>
            ) : null}
            {selectedButton ===
            t("support_pages.technical_support.buttons.button3") ? (
              <div className="form-content">
                <label htmlFor="issues">
                  {t("support_pages.technical_support.how_can_help")}
                </label>
                <select
                  name="issue"
                  id="issue"
                  onChange={handleTechnicalSupportSelectedIssue}
                  style={{
                    color: isTechnicalSupportIssueSelected
                      ? "black"
                      : "#8a7575",
                  }}
                  required
                >
                  <option value="" hidden="">
                    {t("support_pages.technical_support.issue_options.option1")}
                  </option>
                  <option value=" New feature request">
                    {t("support_pages.technical_support.issue_options.option2")}
                  </option>
                  <option value="I want to re-sell your Software">
                    {t("support_pages.technical_support.issue_options.option3")}
                  </option>
                  <option value="Other">
                    {t("support_pages.technical_support.issue_options.option4")}
                  </option>
                </select>
              </div>
            ) : null}
            <div className="form-content">
              <p>{t("support_pages.technical_support.need_help")}</p>
              <div className="connect message">
                <p>{t("support_pages.technical_support.message_box.title")}</p>
                <button onClick={handleMessage}>
                  {t("support_pages.technical_support.message_box.button")}
                </button>
              </div>
              <div className="connect">
                <div className="contact-support">
                  <p>
                    <Link to={AddLangToPath("/priority-support/")}>
                      {t("support_pages.technical_support.call_back.link")}
                    </Link>{" "}
                    {t("support_pages.technical_support.call_back.title")}
                  </p>
                  <button
                    onClick={handleQuestions}
                    disabled={
                      question === QUESTIONS.PURCHASED ||
                      question === QUESTIONS.PURCHASE
                    }
                    style={
                      question === QUESTIONS.PURCHASED ||
                      question === QUESTIONS.PURCHASE
                        ? { backgroundColor: "rgb(85, 85, 85)" }
                        : { backgroundColor: "#0089bf" }
                    }
                  >
                    {t("support_pages.technical_support.call_back.button")}
                  </button>
                </div>
                {openQuestions ? (
                  <div>
                    <p>{question}</p>
                    {showButtons && (
                      <div>
                        <button onClick={answerQuestion}>
                          {t(
                            "support_pages.technical_support.call_back.choice.yes",
                          )}
                        </button>
                        <button onClick={changeQuestion}>
                          {t(
                            "support_pages.technical_support.call_back.choice.no",
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {openMessage ? (
            <div className="request-box">
              <div className="request">
                <h3>
                  {t("support_pages.technical_support.request_form.title")}
                </h3>

                <div className="request-form">
                  <label htmlFor="firstName">
                    {t("support_pages.technical_support.request_form.f_name")}
                  </label>
                  <div></div>
                  <div className="input-fields">
                    <input
                      type="text"
                      name="firstName"
                      id="name"
                      placeholder={t(
                        "support_pages.technical_support.request_form.f_name_placeholder",
                      )}
                      value={formState.firstName}
                      onChange={handleSupportFormInputs}
                      required
                    />
                    <p>{validationMessages.firstName}</p>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="lastName"
                      placeholder={t(
                        "support_pages.technical_support.request_form.l_name_placeholder",
                      )}
                      value={formState.lastName}
                      onChange={handleSupportFormInputs}
                      required
                    />
                    <p>{validationMessages.lastName}</p>
                  </div>
                  <label htmlFor="email">Email</label>
                  <label htmlFor="contactNumber">
                    {t("support_pages.technical_support.request_form.phone")}
                  </label>
                  <div>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="you@domain.com"
                      value={formState.email}
                      onChange={handleSupportFormInputs}
                      required
                    />
                    <p>
                      {validationMessages.email}
                      {/* {showResponse ? (
                        <span style={{ color: "red", fontSize: "0.8rem" }}>
                          {apiResponse.message}
                        </span>
                      ) : null} */}
                    </p>
                  </div>
                  <div>
                    <PhoneInput
                      name="contactNumber"
                      country={"us"}
                      value={phone}
                      onChange={handlePhoneChange}
                    />
                  </div>
                  <label htmlFor="technicalSupportSelectedProduct">
                    {t(
                      "support_pages.technical_support.request_form.product_detail",
                    )}
                  </label>
                  <div></div>
                  <div>
                    <select
                      name="technicalSupportSelectedProduct"
                      id="application"
                      onChange={handleTechnicalSupportSelectedProduct}
                      value={technicalSupportSelectedProduct}
                      style={{
                        color: isTechnicalSupportProductSelected
                          ? "black"
                          : "#8a7575",
                      }}
                      required
                    >
                      <option value="" hidden="">
                        {t("support_pages.technical_support.product_choice")}
                      </option>
                      {products.map((product) => (
                        <option key={product} value={product}>
                          {product}
                        </option>
                      ))}
                    </select>
                    <p>{validationMessages.technicalSupportSelectedProduct}</p>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="version"
                      placeholder={t(
                        "support_pages.technical_support.request_form.version_placeholder",
                      )}
                      value={version}
                      onChange={handleVersionChange}
                      maxLength="5"
                    />
                    <p>{validationMessages.version}</p>
                  </div>
                </div>
                <div className="message-box">
                  <div className="message-box-content">
                    <label htmlFor="message">
                      {t(
                        "support_pages.technical_support.request_form.message",
                      )}
                    </label>
                    <p>{text.length}/1000</p>
                  </div>
                  <div className="text-message">
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="3"
                      maxLength="1000"
                      placeholder={t(
                        "support_pages.technical_support.request_form.message_placeholder",
                      )}
                      required
                      value={text}
                      onChange={handleCount}
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          t(
                            "support_pages.technical_support.validation_messages.message",
                          ),
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                    />
                    <p>{validationMessages.message}</p>
                  </div>
                </div>
                <div className="request-form-buttons">
                  <button className="close-form" onClick={handleMessage}>
                    {t("support_pages.technical_support.request_form.close")}
                  </button>
                  <button
                    className="submit-form"
                    type="submit"
                    onClick={handleSupportForm}
                  >
                    {t("support_pages.technical_support.request_form.submit")}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

Button.propTypes = {
  name: PropTypes.string.isRequired,
  selectedButton: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  handleTechnicalSupportSelectedProduct: PropTypes.func.isRequired,
};

export default TechnicalSupport;
