import React from "react";
import PropTypes from "prop-types";
import "../../static/css/productPage/productInfo.css";
import FreeDownload from "./FreeDownload";
const ProductInfo = ({ detail, title, downloadProduct, type }) => {
  return (
    <div className="product-info">
      <div className="lines" dangerouslySetInnerHTML={{ __html: detail }}></div>
      <FreeDownload
        title={title}
        downloadProduct={downloadProduct}
        type={type}
      />
    </div>
  );
};

ProductInfo.propTypes = {
  detail: PropTypes.string,
  title: PropTypes.string,
  downloadProduct: PropTypes.string,
  type: PropTypes.string,
};

export default ProductInfo;
