import React from 'react'
import PropTypes from 'prop-types';
import '../../static/css/productPage/getStartedNow.css'
import CartButtons from './CartButtons'

const GetStartedNow = ({ title, downloadProduct, cartItem, type, inApp, cartTitle, cartDescription, getStartedContent }) => {
    return (
        <div className="get-started-now">
            <p>Get Started Now!</p>
            {getStartedContent ? <span>{getStartedContent}</span> : null}
            <div className="button-group">
                <CartButtons downloadProduct={downloadProduct} cartItem={cartItem} type={type} inApp={inApp} cartTitle={cartTitle} cartDescription={cartDescription} />
            </div>
        </div>
    )
}

GetStartedNow.propTypes = {
    title: PropTypes.string.isRequired,
    downloadProduct: PropTypes.func.isRequired,
    cartItem: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    inApp: PropTypes.bool.isRequired,
    cartTitle: PropTypes.string.isRequired,
    cartDescription: PropTypes.string.isRequired,
    getStartedContent: PropTypes.string
};

export default GetStartedNow;
