import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../static/css/supportPages/lostLicenseKey.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LostLicenseAPI } from "../../api/lostLicenseKeyAPI";
import { useTranslation } from "react-i18next";
import ThankYou from "../../components/ThankYou";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getShoppingCartLoginStatus } from "../../api/ShoppingCartLoginStatus";

const applications = [
  "Auto FTP Manager",
  "Dictation Pro",
  "Document Translator",
  "FTP Manager Lite",
  "FTP Manager Pro",
  "IP Camera Viewer",
  "My Screen Recorder",
  "My Screen Recorder Pro",
  "Paper Scanner Pro",
  "Security Monitor Pro",
  "Text Speaker Live",
  "WebCam Monitor",
];

const LostLicenseKey = () => {
  const { t } = useTranslation();
  const { isuser_loggedin } = getShoppingCartLoginStatus();
  const navigate = useNavigate();

  const [phone, setPhone] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [showResponse, setShowResponse] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [dateError, setDateError] = useState(false);
  const [application, setApplication] = useState("");

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      t(
        "support_pages.lost_license.license_key_form.validation_messages.f_name",
      ),
    ),
    lastName: Yup.string().required(
      t(
        "support_pages.lost_license.license_key_form.validation_messages.l_name",
      ),
    ),
    email: Yup.string()
      .email(
        t(
          "support_pages.lost_license.license_key_form.validation_messages.email1",
        ),
      )
      .required(
        t(
          "support_pages.lost_license.license_key_form.validation_messages.email2",
        ),
      ),
    application: Yup.string().required(
      t(
        "support_pages.lost_license.license_key_form.validation_messages.product",
      ),
    ),
  });

  useEffect(() => {
    document.title = t("support_pages.lost_license.title");
    window.scrollTo(0, 0);

    if (apiResponse !== null) {
      setShowResponse(true);
      if (apiResponse.status === 200) {
        setTimeout(() => {
          navigate("/");
        }, 10000);
      } else {
        setTimeout(() => {
          setShowResponse(false);
        }, 5000);
      }
    }
  }, [apiResponse, navigate]);

  const handleChange = (date) => {
    setStartDate(date);
    setDateError(!date);
  };

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setApiResponse(null);

    if (!startDate) {
      setDateError(true);
      return;
    }

    const formattedDate = `${startDate.getDate().toString().padStart(2, "0")}/${(startDate.getMonth() + 1).toString().padStart(2, "0")}/${startDate.getFullYear()}`;
    const formData = { ...values, phone, formattedDate };

    try {
      const response = await LostLicenseAPI(formData);
      setApplication(values.application);
      setApiResponse(response);
      if (response.status === 200) {
        resetForm();
        setPhone("");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiResponse({
        status: 500,
        message: "An error occurred. Please try again.",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <html lang={localStorage.getItem("lang")}></html>
        <meta
          name="description"
          content="Retrieve your lost license key by using an automated system."
        />
        <meta
          name="keywords"
          content="lost license number, lost license key, lost license code, license key missing, license lost, lost product key"
        />
      </Helmet>
      {showResponse && apiResponse && apiResponse.status === 200 ? (
        <ThankYou applicationName={application} />
      ) : (
        <>
          <div className="key-header">
            <h1>{t("support_pages.lost_license.title")}</h1>
            {isuser_loggedin ? (
              <p>
                {t("support_pages.lost_license.description3")}
                <Link to={`${process.env.REACT_APP_SHOPPING_CART_URL}/login/`}>
                  {t("support_pages.lost_license.your_account")}
                </Link>
                {t("support_pages.lost_license.description4")}
              </p>
            ) : (
              <p>
                {t("support_pages.lost_license.description1")}
                <Link to={`${process.env.REACT_APP_SHOPPING_CART_URL}/login/`}>
                  {t("support_pages.lost_license.sign_in")}
                </Link>
                {t("support_pages.lost_license.description2")}
              </p>
            )}
          </div>
          <div className="lost-license-form">
            {showResponse && apiResponse ? (
              <span style={{ color: "red", fontSize: "0.8vw" }}>
                {apiResponse.message}
              </span>
            ) : null}
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                application: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, formikBag) => {
                handleFormSubmit(values, formikBag);
              }}
            >
              {({ values }) => {
                const selected = values.application !== "";
                return (
                  <Form className="lost-license-form">
                    <div className="key-form">
                      <div className="key-signin">
                        <p>
                          {t(
                            "support_pages.lost_license.license_key_form.question",
                          )}
                        </p>
                        <p>
                          {t(
                            "support_pages.lost_license.license_key_form.description",
                          )}
                        </p>
                        <p>
                          {t(
                            "support_pages.lost_license.license_key_form.description2",
                          )}
                        </p>
                        <div className="key-account-form">
                          <div>
                            <div>
                              {" "}
                              <label htmlFor="firstName">
                                {t(
                                  "support_pages.lost_license.license_key_form.label.full_name",
                                )}
                              </label>
                            </div>
                            <Field
                              name="firstName"
                              placeholder={t(
                                "support_pages.lost_license.license_key_form.placeholders.f_name",
                              )}
                            />
                            <ErrorMessage
                              className="lost-key-error-message"
                              name="firstName"
                              component="div"
                            />
                          </div>
                          <div className="last-name">
                            <Field
                              name="lastName"
                              placeholder={t(
                                "support_pages.lost_license.license_key_form.placeholders.l_name",
                              )}
                            />
                            <ErrorMessage
                              className="lost-key-error-message"
                              name="lastName"
                              component="div"
                            />
                          </div>

                          <div>
                            <div className="email-label">
                              <label htmlFor="email">Email</label>
                            </div>
                            <Field
                              type="email"
                              name="email"
                              placeholder="you@domain.com"
                            />
                            <ErrorMessage
                              className="lost-key-error-message"
                              name="email"
                              component="div"
                            />
                          </div>
                          <div>
                            <div className="phone-label">
                              <label htmlFor="phone">
                                {t(
                                  "support_pages.lost_license.license_key_form.label.phone",
                                )}
                              </label>
                            </div>

                            <PhoneInput
                              country={"us"}
                              value={phone}
                              onChange={(phone) => setPhone(phone)}
                            />
                          </div>

                          <div>
                            <div className="application-label">
                              <label htmlFor="application">
                                {t(
                                  "support_pages.lost_license.license_key_form.label.application",
                                )}
                              </label>
                            </div>

                            <Field
                              as="select"
                              name="application"
                              id="application"
                              style={{ color: selected ? "black" : "#8a7575" }}
                            >
                              <option value="" hidden="">
                                {t(
                                  "support_pages.lost_license.license_key_form.placeholders.product",
                                )}
                              </option>
                              {applications.map((app) => (
                                <option key={app} value={app}>
                                  {app}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              className="lost-key-error-message application-error"
                              name="application"
                              component="div"
                            />
                          </div>
                          <div className="date-box">
                            <label htmlFor="date">
                              {t(
                                "support_pages.lost_license.license_key_form.label.date",
                              )}
                            </label>
                            <DatePicker
                              selected={startDate}
                              onChange={handleChange}
                              showIcon
                              toggleCalendarOnIconClick
                              placeholderText="dd/mm/yyyy"
                              dateFormat="dd/MM/yyyy"
                              name="purchaseDate"
                              dropdownMode="select"
                              id="formattedDate"
                            />
                            {dateError && (
                              <span className="date-error-message">
                                Please enter approximate date of purchase
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="submit">
                      {t("support_pages.lost_license.license_key_form.submit")}
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </>
  );
};

export default LostLicenseKey;
