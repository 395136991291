import React from "react";
import uuid from "react-uuid";
import PropTypes from "prop-types";
import "../../static/css/productPage/quickTips.css";
import { useTranslation } from "react-i18next";

const QuickTips = ({ quickLink }) => {
  const { t } = useTranslation();

  return (
    <div className="quicktips">
      <div className="sidecardheader">{t("product_page.quick_tips")}</div>
      <div className="sidecardsection">
        <ul>
          {quickLink.map((data) => {
            return (
              <li key={uuid()}>
                <a href={`/${data.product_name_slug}/${data.article_slug}`}>
                  {data.article_title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

QuickTips.propTypes = {
  quickLink: PropTypes.array,
};

export default QuickTips;
