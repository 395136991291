import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "../../static/css/productPage/freeDownload.css";
import { useTranslation } from "react-i18next";

const FreeDownload = ({ title, downloadProduct, type }) => {
  const { t } = useTranslation();
  return (
    <div className="free-download">
      <div className="content-top">
        {type === "free" ? (
          <p className="heading">
            {t("product_page.cart.free_heading1")} {title}{" "}
            {t("product_page.cart.free_heading2")}
          </p>
        ) : (
          <p className="heading">{t("product_page.free_download.title")}</p>
        )}
        <span>{t("product_page.free_download.description")}</span>
        <Link to={downloadProduct}>{t("product_page.cart.download")}</Link>
      </div>
    </div>
  );
};

FreeDownload.propTypes = {
  title: PropTypes.string,
  downloadProduct: PropTypes.string,
  type: PropTypes.string,
};

export default FreeDownload;
