import React, { useEffect, useState } from "react";

import "../../static/css/common/footer.css";

import { Link } from "react-router-dom";

import { Link as Scroll } from "react-scroll";

import { useTranslation } from "react-i18next";

import { AddLangToPath } from "../../helper/AddLanguagePath";

const Footer = () => {
  const { t } = useTranslation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowWidth(window.innerWidth);
    }, 200);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderDownloadLink = () => {
    if (windowWidth > 1000) {
      return (
        <span>
          <Scroll
            to="download-header"
            spy={true}
            smooth={true}
            offset={-100}
            duration={800}
          >
            <Link className="footer-text" to={AddLangToPath("/download/")}>
              {t("footer.download")}
            </Link>
          </Scroll>
        </span>
      );
    }

    return null;
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <span>
          <Scroll
            to="image-slider"
            spy={true}
            smooth={true}
            offset={-100}
            duration={800}
          >
            <Link className="footer-text" to={AddLangToPath("/")}>
              {t("footer.home")}
            </Link>
          </Scroll>
        </span>{" "}
        | {renderDownloadLink()} <span id="download-bar">|</span>{" "}
        <span>
          <Scroll
            to="technical-header"
            spy={true}
            smooth={true}
            offset={-100}
            duration={800}
          >
            <Link
              className="footer-text"
              to={AddLangToPath("/product-support/")}
            >
              {t("footer.support")}
            </Link>
          </Scroll>
        </span>{" "}
        |{" "}
        <span>
          <Scroll
            to="aboutus-header"
            spy={true}
            smooth={true}
            offset={-100}
            duration={800}
          >
            <Link
              className="footer-text"
              to={AddLangToPath("/about-deskshare/")}
            >
              {t("footer.about")}
            </Link>
          </Scroll>
        </span>{" "}
        |{" "}
        <span>
          <Scroll
            to="policy-header"
            spy={true}
            smooth={true}
            offset={-100}
            duration={800}
          >
            <Link
              className="footer-text"
              to={AddLangToPath("/privacy-policy/")}
            >
              {t("footer.privacy_policy")}
            </Link>
          </Scroll>
        </span>{" "}
        |{" "}
        <span>
          <Scroll
            to="contactus-header"
            spy={true}
            smooth={true}
            offset={-100}
            duration={800}
          >
            <Link className="footer-text" to={AddLangToPath("/contact-us/")}>
              {t("footer.contact_us")}
            </Link>
          </Scroll>
        </span>
      </div>

      <div className="end">{t("footer.copyrite")}</div>
    </footer>
  );
};

function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);

      func(...args);
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
  };
}

export default Footer;
