import React, { useEffect, useState } from "react";
import "../../static/css/company/aboutDeskshare.css";
import { Link } from "react-router-dom";
import { commonAPI } from "../../api/commonAPI";
import { useTranslation } from "react-i18next";
import { AddLangToPath } from "../../helper/AddLanguagePath";

const AboutDeskshare = () => {
  const { t } = useTranslation();
  document.title = t("company.about_us.title_name");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 430);
  const [aboutUsData, setAboutUsData] = useState();

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setIsMobile(newWidth < 763);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const apiEndpoint = isMobile ? "about-us-mobile" : "about-us";
    commonAPI(apiEndpoint).then((result) => setAboutUsData(result));
  }, [isMobile]);

  if (aboutUsData) {
    return (
      <>
        <div className="aboutus-header">
          <div>
            <span>{t("company.about_us.title_name")} </span>
            <br />
            <span>{t("company.about_us.title_info")}</span>
          </div>
        </div>
        <div className="about-info">
          <p>{t("company.about_us.about_info.info_text1")}</p>
          <p>
            {t("company.about_us.about_info.info_text2_part1")}
            <Link to={"/video-surveillance-software"}>
              {t("company.about_us.about_info.info_text2_link1")}
            </Link>
            {t("company.about_us.about_info.info_text2_part2")}
            <Link to={"/text-to-speech-software"}>
              {t("company.about_us.about_info.info_text2_link2")}
            </Link>
            {t("company.about_us.about_info.info_text2_part3")}
          </p>
          <p>{t("company.about_us.about_info.info_text3")}</p>
          <p>
            {t("company.about_us.about_info.info_text4_part1")}
            <Link to={"/screen-recorder"}>
              {t("company.about_us.about_info.info_text4_link1")}
            </Link>
            {t("company.about_us.about_info.info_text4_part2")}
            <Link to={"/ftp-client"}>
              {t("company.about_us.about_info.info_text4_link2")}
            </Link>
            {t("company.about_us.about_info.info_text4_part3")}
          </p>
          <p>{t("company.about_us.about_info.info_text5")}</p>
        </div>
        <div className="aboutus-container">
          <p className="solution">{t("company.about_us.solution")}</p>
          <div className="product-cards">
            {Object.entries(aboutUsData).map(([categoryName, products]) => (
              <div key={categoryName} className="product-container">
                {products.map(
                  (product) =>
                    product.product_name && (
                      <Link
                        key={product.product_name}
                        to={AddLangToPath(`/${product.product_slug}`)}
                      >
                        <div className="product-box">
                          <img
                            src={`${process.env.REACT_APP_BACKEND_URL}/media/${product.product_icon}`}
                            alt={product.product_name}
                          />
                          <h4>{product.product_name}</h4>
                          <p>{product.product_title}</p>
                        </div>
                      </Link>
                    ),
                )}
              </div>
            ))}
          </div>
          {/* <p className='press'>{t('company.about_us.press')}</p>
          <div className="media-container">
            <Link to={AddLangToPath('/media-coverage/')}>
              <p className='media-title' >{t('company.about_us.media')}</p>
              <p className='media-description'>{t('company.about_us.media_description')}</p>
            </Link>
          </div> */}
        </div>
      </>
    );
  }
};

export default AboutDeskshare;
