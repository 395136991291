import React, { useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { AddLangToPath } from "../helper/AddLanguagePath";

const NotFound = () => {
  let location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .post("/api/error", {
        error: location.pathname,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
    navigate(AddLangToPath("/"));
  }, [location.pathname, navigate]);
  return <p>Redirecting...</p>;
};

export default NotFound;
