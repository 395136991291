import React from "react";
import { useTranslation } from "react-i18next";

const DownloadTitle = () => {
  const { t } = useTranslation();
  return (
    <div className="download-header">
      <h1 className="download-title">{t("download_page.title")}</h1>
      <div className="download-description">
        <p>
          {t("download_page.description1")}
          <br />
          {t("download_page.description2")}
        </p>
      </div>
    </div>
  );
};

export default DownloadTitle;
