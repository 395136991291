import React, { useEffect, useState } from "react";
import "../static/css/purchasePage.css";
import { Link, useParams } from "react-router-dom";
import { fetchData } from "../api/productPageAPI";
import { AddLangToPath } from "../helper/AddLanguagePath";
// import optimizedConvertAndRound from '../helper/OptimizedConvertAndRoundPrice';

const PurchasePage = () => {
  let { productSlug } = useParams();
  const [productPrice, setProductPrice] = useState(); //actual product price
  const [counter, setCounter] = useState({ annual: 1, lifetime: 1 });
  const localStorageValues = JSON.parse(localStorage.getItem("currencyData"));
  const { currency_code, currency_symbol, exchange_rate } =
    localStorageValues || {};

  function getDeviceType(userAgent) {
    const mobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|Touch/i.test(
        userAgent,
      );

    return mobile;
  }

  const updateCounter = (counterKey, operation) => {
    if (operation === "increment") {
      setCounter((prevCounters) => ({
        ...prevCounters,
        [counterKey]: prevCounters[counterKey] + 1,
      }));
    } else if (operation === "decrement" && counter[counterKey] > 0) {
      setCounter((prevCounters) => ({
        ...prevCounters,
        [counterKey]: prevCounters[counterKey] - 1,
      }));
    }
  };

  useEffect(() => {
    fetchData(`${productSlug}/cart`).then((result) => {
      const transformedResult = result.reduce((acc, item) => {
        if (!acc.product) {
          acc = {
            product: item.product,
            product_name: item.product_name,
            cart_type: item.cart_type,
            cart: [],
          };
        }

        acc.cart.push({
          id: item.id,
          cart_catagory: item.cart_catagory,
          catagory_price: item.catagory_price,
          catagory_id: item.catagory_id,
        });

        return acc;
      }, {});

      setProductPrice(transformedResult);
    });
  }, [productSlug]);

  const generateQueryString = (categoryId, count) => {
    return `${process.env.REACT_APP_SHOPPING_CART_URL}/?add-to-cart=${Array(
      count,
    )
      .fill(categoryId)
      .join(",")}`;
  };

  return (
    <div className="purchase-page">
      <section className="purchase-title-block">
        <h3 className="purchase-title">
          Pick your plan for {productPrice?.product_name}
        </h3>
      </section>
      <section className="purchase-plane">
        <div className="plan">
          {productPrice?.cart_type === "dropdown" ? (
            <h3 className="plan-title">Annual License</h3>
          ) : (
            <div className="credit-title">
              <h3 className="plan-title">Most Popular Plan</h3>
              <p>
                Get <b>45,000</b> Text to Speech words per month (Resets
                monthly)
              </p>
            </div>
          )}
          <div
            className={
              productPrice?.cart_type === "dropdown"
                ? "plan-container"
                : "credit-plan-container"
            }
          >
            {productPrice?.cart_type === "dropdown" ? (
              <ul>
                <li>Full access for a year</li>
                <li>Get all premium features</li>
                <li>All updates for one year</li>
                <li>Customer support via email and chat</li>
              </ul>
            ) : (
              <ul>
                <li>Perfect for regular use</li>
                <li>Use all 480 premium voices</li>
                <li>Voices available in 78 languages</li>
                <li>Over 30 voices with emotional expressions</li>
                <li>Royalty-free commercial use</li>
              </ul>
            )}
            {productPrice?.cart_type === "dropdown" ? (
              <div className="price-container">
                <p className="quant">Quantity</p>
                <div className="counter-buttons">
                  <button
                    className="counter-button"
                    onClick={() => updateCounter("annual", "decrement")}
                  >
                    -
                  </button>
                  <button className="value-counter">{counter.annual}</button>
                  <button
                    className="counter-button"
                    onClick={() => updateCounter("annual", "increment")}
                  >
                    +
                  </button>
                </div>
                <p className="pricing">
                  {currency_symbol}
                  {/* {optimizedConvertAndRound(productPrice?.cart?.[0].catagory_price, exchange_rate, counter.annual)}{" "} */}
                  {parseFloat(
                    (
                      productPrice?.cart?.[0].catagory_price *
                      exchange_rate *
                      counter.annual
                    ).toFixed(2),
                  )}{" "}
                  {currency_code}
                </p>
                <p
                  className="pricing-block"
                  style={counter.annual <= 0 ? { cursor: "not-allowed" } : {}}
                >
                  <Link
                    className="purchase-button"
                    type="button"
                    disabled={counter.annual <= 0}
                    style={
                      counter.annual <= 0
                        ? { pointerEvents: "none", backgroundColor: "gray" }
                        : {}
                    }
                    to={generateQueryString(
                      productPrice?.cart?.[0].catagory_id,
                      counter.annual,
                    )}
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M17 18C15.89 18 15 18.89 15 20C15 20.5304 15.2107 21.0391 15.5858 21.4142C15.9609 21.7893 16.4696 22 17 22C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20C19 19.4696 18.7893 18.9609 18.4142 18.5858C18.0391 18.2107 17.5304 18 17 18ZM1 2V4H3L6.6 11.59L5.24 14.04C5.09 14.32 5 14.65 5 15C5 15.5304 5.21071 16.0391 5.58579 16.4142C5.96086 16.7893 6.46957 17 7 17H19V15H7.42C7.3537 15 7.29011 14.9737 7.24322 14.9268C7.19634 14.8799 7.17 14.8163 7.17 14.75C7.17 14.7 7.18 14.66 7.2 14.63L8.1 13H15.55C16.3 13 16.96 12.58 17.3 11.97L20.88 5.5C20.95 5.34 21 5.17 21 5C21 4.73478 20.8946 4.48043 20.7071 4.29289C20.5196 4.10536 20.2652 4 20 4H5.21L4.27 2M7 18C5.89 18 5 18.89 5 20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22C7.53043 22 8.03914 21.7893 8.41421 21.4142C8.78929 21.0391 9 20.5304 9 20C9 19.4696 8.78929 18.9609 8.41421 18.5858C8.03914 18.2107 7.53043 18 7 18Z"
                        fill="white"
                      ></path>
                    </svg>
                    Buy Now
                  </Link>
                </p>
                <p className="plan-type">Billed Annually</p>
              </div>
            ) : (
              <div className="price-container">
                <p className="credit-quant">
                  {productPrice?.cart?.[0].cart_catagory}
                </p>
                <p className="pricing-block">
                  <Link
                    className="credit-purchase-button"
                    to={`${process.env.REACT_APP_SHOPPING_CART_URL}/?add-to-cart=${productPrice?.cart?.[0].catagory_id}`}
                    target="_blank"
                  >
                    {currency_symbol}
                    {/* {optimizedConvertAndRound(productPrice?.cart?.[0].catagory_price, exchange_rate)}{" "} */}
                    {parseFloat(
                      (
                        productPrice?.cart?.[0].catagory_price * exchange_rate
                      ).toFixed(2),
                    )}{" "}
                    {currency_code} / month
                  </Link>
                </p>
                <p className="plan-type">Billed Monthly</p>
              </div>
            )}
          </div>
        </div>
        <div className="plan">
          {productPrice?.cart_type === "dropdown" ? (
            <h3 className="plan-title">Lifetime License</h3>
          ) : (
            <div className="credit-title">
              <h3 className="plan-title">Go Premium</h3>
              <p>
                Get <b>100,000</b> Text to Speech words per month (Resets
                monthly)
              </p>
            </div>
          )}
          <div
            className={
              productPrice?.cart_type === "dropdown"
                ? "plan-container"
                : "credit-plan-container"
            }
          >
            {productPrice?.cart_type === "dropdown" ? (
              <ul>
                <li>One time payment for endless access</li>
                <li>Get all premium features</li>
                <li>Free updates for two years</li>
                <li>Optional Discounted Updates After 2 Years </li>
                <li>Customer support via email and chat</li>
              </ul>
            ) : (
              <ul>
                <li>Perfect for power use</li>
                <li>Use all 480 premium voices</li>
                <li>Voices available in 78 languages</li>
                <li>Over 30 voices with emotional expressions</li>
                <li>Royalty-free commercial use</li>
              </ul>
            )}
            {productPrice?.cart_type === "dropdown" ? (
              <div className="price-container">
                <p className="quant">Quantity</p>
                <div className="counter-buttons">
                  <button
                    className="counter-button"
                    onClick={() => updateCounter("lifetime", "decrement")}
                  >
                    -
                  </button>
                  <button className="value-counter">{counter.lifetime}</button>
                  <button
                    className="counter-button"
                    onClick={() => updateCounter("lifetime", "increment")}
                  >
                    +
                  </button>
                </div>
                <p className="pricing">
                  {currency_symbol}
                  {/* {optimizedConvertAndRound(productPrice?.cart?.[1].catagory_price, exchange_rate, counter.lifetime)}{" "} */}
                  {parseFloat(
                    (
                      productPrice?.cart?.[1].catagory_price *
                      exchange_rate *
                      counter.lifetime
                    ).toFixed(2),
                  )}{" "}
                  {currency_code}
                </p>
                <p
                  className="pricing-block"
                  style={counter.lifetime <= 0 ? { cursor: "not-allowed" } : {}}
                >
                  <Link
                    className="purchase-button"
                    type="button"
                    disabled={counter.lifetime <= 0}
                    style={
                      counter.lifetime <= 0
                        ? { pointerEvents: "none", backgroundColor: "gray" }
                        : {}
                    }
                    to={generateQueryString(
                      productPrice?.cart?.[1].catagory_id,
                      counter.lifetime,
                    )}
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M17 18C15.89 18 15 18.89 15 20C15 20.5304 15.2107 21.0391 15.5858 21.4142C15.9609 21.7893 16.4696 22 17 22C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20C19 19.4696 18.7893 18.9609 18.4142 18.5858C18.0391 18.2107 17.5304 18 17 18ZM1 2V4H3L6.6 11.59L5.24 14.04C5.09 14.32 5 14.65 5 15C5 15.5304 5.21071 16.0391 5.58579 16.4142C5.96086 16.7893 6.46957 17 7 17H19V15H7.42C7.3537 15 7.29011 14.9737 7.24322 14.9268C7.19634 14.8799 7.17 14.8163 7.17 14.75C7.17 14.7 7.18 14.66 7.2 14.63L8.1 13H15.55C16.3 13 16.96 12.58 17.3 11.97L20.88 5.5C20.95 5.34 21 5.17 21 5C21 4.73478 20.8946 4.48043 20.7071 4.29289C20.5196 4.10536 20.2652 4 20 4H5.21L4.27 2M7 18C5.89 18 5 18.89 5 20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22C7.53043 22 8.03914 21.7893 8.41421 21.4142C8.78929 21.0391 9 20.5304 9 20C9 19.4696 8.78929 18.9609 8.41421 18.5858C8.03914 18.2107 7.53043 18 7 18Z"
                        fill="white"
                      ></path>
                    </svg>
                    Buy Now
                  </Link>
                </p>
                <p className="plan-type">Billed Once</p>
              </div>
            ) : (
              <div className="price-container">
                <p className="credit-quant">
                  {productPrice?.cart?.[1].cart_catagory}
                </p>
                <p className="pricing-block">
                  <Link
                    className="credit-purchase-button"
                    to={`${process.env.REACT_APP_SHOPPING_CART_URL}/?add-to-cart=${productPrice?.cart?.[1].catagory_id}`}
                    target="_blank"
                  >
                    {currency_symbol}
                    {/* {optimizedConvertAndRound(productPrice?.cart?.[1].catagory_price, exchange_rate)}{" "} */}
                    {parseFloat(
                      (
                        productPrice?.cart?.[1].catagory_price * exchange_rate
                      ).toFixed(2),
                    )}{" "}
                    {currency_code} / month
                  </Link>
                </p>
                <p className="plan-type">Billed Monthly</p>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="purchase-content">
        <p className="satisfaction">Satisfaction Guaranteed</p>
        <p className="satisfaction-text">
          <img src="/images/guaranteed.png" alt="guaranteed_img" />
          We guarantee 100% satisfaction for all of our products.
        </p>
        <p className="immediate-content">Immediate Delivery</p>
        <p className="immediate-text">
          Receive your license key via email immediately after purchase.
        </p>
        <p className="immediate-text">
          {!getDeviceType(navigator.userAgent) ? (
            <Link to={AddLangToPath("/download")}>Download</Link>
          ) : (
            "Download"
          )}{" "}
          and install the trial version, then enter the license key to activate
          it to the full version. If you purchase from within the application,
          it will instantly activate to the full version.
        </p>
        <p className="lifetime-support">Lifetime Technical Support</p>
        <p className="lifetime-text">
          Enjoy free technical support via email and chat for as long as you own
          the product. For additional support options, please see our{" "}
          <Link to={AddLangToPath("/articles")}>Online Resources</Link> or
          consider our{" "}
          <Link to={AddLangToPath("/priority-support")}>
            Annual Priority Support
          </Link>{" "}
          plan for comprehensive phone support.
        </p>
        <p className="upgrade-policy">
          Upgrade Policy for {productPrice?.product_name}
        </p>
        <p className="upgrade-text">
          All program updates are FREE as long as you maintain your{" "}
          {productPrice?.cart_type === "dropdown"
            ? "Annual Subscription"
            : "subscription"}
          .{" "}
          {productPrice?.cart_type === "dropdown" ? (
            <>
              Lifetime license holders will receive free updates for two years
              from the date of purchase. After two years, upgrades will be
              available at a discounted price.
            </>
          ) : null}
        </p>
      </section>
    </div>
  );
};

export default PurchasePage;
