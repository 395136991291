import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import "../static/css/productPage/productPage.css";
import ProductTitle from "../components/productPage/ProductTitle";
import Data from "../data/productPages.json";
import { useParams } from "react-router-dom";
import { fetchData } from "../api/productPageAPI";
import ProductFeatures from "../components/productPage/ProductFeatures";
import Video from "../components/productPage/Video";
import Comments from "../components/productPage/Comments";
import QuickLetter from "../components/productPage/QuickLetter";
import CartButtons from "../components/productPage/CartButtons";
import ProductVersion from "../components/productPage/ProductVersion";
import ProductInfo from "../components/productPage/ProductInfo";
import Characteristics from "../components/productPage/Characteristics";
import Comparison from "../components/productPage/Comparison";
import GetStartedNow from "../components/productPage/GetStartedNow";

const ProductPage = () => {
  const { productSlug } = useParams();
  const productData = useMemo(() => Data[productSlug], [productSlug]);
  const [product, setProduct] = useState({});
  const lang = localStorage.getItem("lang");
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData(productSlug)
      .then((result) => setProduct(result))
      .catch((error) => console.error("error in product page", error));
  }, [productSlug]);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      if (
        (windowWidth < 1031 && currentWidth >= 1031) ||
        (windowWidth >= 1031 && currentWidth < 1031)
      ) {
        setWindowWidth(currentWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  if (product?.meta_data) {
    return (
      <>
        {product.meta_data[0]?.meta_description ? (
          <Helmet>
            <html lang={lang}></html>
            <meta
              name="description"
              content={product?.meta_data[0].meta_description}
            />
            <meta
              name="keywords"
              content={product?.meta_data[0].meta_keywords}
            />
            <meta
              property="og:title"
              content={product?.meta_data[0].meta_title}
            />
            <meta
              property="og:description"
              content={product?.meta_data[0].meta_description}
            />
            <meta
              property="og:image"
              content={product?.meta_data[0].meta_image}
            />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
          </Helmet>
        ) : null}
        <div className="productPage">
          <ProductTitle
            title={product?.product_name}
            subTitle={product?.product_title}
            downloadProduct={product?.download_link}
            type={product?.pricing_type}
            inApp={product?.in_app_purchase}
          />
          <div className="shadow1"></div>
          <div className="shadow2"></div>
          {windowWidth > 1031 ? (
            <div className="product">
              <ProductFeatures
                features={product?.feature}
                type={product?.pricing_type}
                inApp={product?.in_app_purchase}
              />
              <div className="right-container">
                <Video
                  video={product?.videos}
                  videoImage={product?.video_image}
                  images={product?.screenshot}
                />
                <Comments review={product?.review} />
                <QuickLetter quickLinks={product?.article} />
              </div>
              <CartButtons
                downloadProduct={product?.download_link}
                cartItem={product?.cart_item}
                type={product?.pricing_type}
                inApp={product?.in_app_purchase}
                cartTitle={product?.cart_title}
                cartDescription={product?.cart_description}
              />
              <ProductVersion
                version={product?.current_version}
                newVersion={product?.whats_new_slug}
                os={product?.supporting_os}
                server={product?.product_server}
              />
              <ProductInfo
                detail={product?.product_information}
                title={product?.product_name}
                downloadProduct={product?.download_link}
                type={product?.pricing_type}
              />
              <Characteristics featureTable={product?.feature_table} />
              {!productData.comparisonTableData &&
              product?.comparison_table ? null : (
                <Comparison
                  comparison={productData.comparisonTableData}
                  comparisonTable={product?.comparison_table}
                />
              )}
              <GetStartedNow
                title={product?.product_name}
                downloadProduct={product?.download_link}
                cartItem={product?.cart_item}
                type={product?.pricing_type}
                inApp={product?.in_app_purchase}
                cartTitle={product?.cart_title}
                cartDescription={product?.cart_description}
                getStartedContent={product?.get_started_now}
              />
              {/* <Cart download={downloadLink} productType={product?.pricing_type} inApp={product?.in_app_purchase} cartItem={cartItem} cartType={cartType} cartTitle={cartTitle} cartDescription={cartDescription} downloadProduct={downloadProduct} /> */}
            </div>
          ) : (
            <div className="product">
              <ProductFeatures
                features={product?.feature}
                type={product?.pricing_type}
              />
              <CartButtons
                downloadProduct={product?.download_link}
                cartItem={product?.cart_item}
                type={product?.pricing_type}
                inApp={product?.in_app_purchase}
                cartTitle={product?.cart_title}
                cartDescription={product?.cart_description}
              />
              <ProductVersion
                version={product?.current_version}
                newVersion={product?.whats_new_slug}
                os={product?.supporting_os}
                server={product?.product_server}
              />
              <Video
                video={product?.videos}
                videoImage={product?.video_image}
                images={product?.screenshot}
              />
              <ProductInfo
                detail={product?.product_information}
                title={product?.product_name}
                downloadProduct={product?.download_link}
                type={product?.pricing_type}
              />
              <Characteristics featureTable={product?.feature_table} />
              <Comments review={product?.review} />
              {!productData.comparisonTableData &&
              product?.comparison_table ? null : (
                <Comparison
                  comparison={productData.comparisonTableData}
                  comparisonTable={product?.comparison_table}
                />
              )}
              <QuickLetter quickLinks={product?.article} />
              <GetStartedNow
                title={product?.product_name}
                downloadProduct={product?.download_link}
                cartItem={product?.cart_item}
                type={product?.pricing_type}
                inApp={product?.in_app_purchase}
                cartTitle={product?.cart_title}
                cartDescription={product?.cart_description}
                getStartedContent={product?.get_started_now}
              />
              {/* <Cart download={downloadLink} productType={product?.pricing_type} inApp={product?.in_app_purchase} cartItem={cartItem} cartType={cartType} cartTitle={cartTitle} cartDescription={cartDescription} downloadProduct={downloadProduct} /> */}
            </div>
          )}
        </div>
      </>
    );
  }
};

export default ProductPage;
