import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import { Link } from "react-router-dom";
import { MdOutlineFileDownload } from "react-icons/md";
import { commonAPI } from "../../api/commonAPI";
import { useTranslation } from "react-i18next";

const DownloadTable = () => {
  const { t } = useTranslation();
  const [downloadData, setDownloadData] = useState(null);
  const [osVersion, setOsVersion] = useState("Unknown OS");

  useEffect(() => {
    window.scrollTo(0, 0);
    commonAPI("download").then((result) => setDownloadData(result));
  }, []);

    useEffect(() => {
        const getOSVersion = async () => {
            if (navigator.userAgentData) {
                try {
                    const ua = await navigator.userAgentData.getHighEntropyValues(["platformVersion"]);
                    const version = parseFloat(ua.platformVersion);
                    let osVersion = "Unknown OS";
                    if (version === 0) {
                        osVersion = "8";
                    } else if (version >= 1 && version <= 10) {
                        osVersion = "10";
                    } else if (version >= 13) {
                        osVersion = "11";
                    }
                    setOsVersion(osVersion);
                } catch (error) {
                    console.error(error);
                    setOsVersion("Unknown OS");
                }
            } else {
                setOsVersion("Unknown OS");
            }
        };
        getOSVersion();
    }, []);

  if (downloadData) {
    return (
      <div className="download-content">
        {Object.keys(downloadData).map((type) => (
          <div key={uuid()} className="download-type">
            <p className="type-name">{type}</p>
            <table className="download-product">
              <thead className="download-product-heading">
                <tr>
                  <th>{t("download_page.table.product_name")}</th>
                  <th>{t("download_page.table.description")}</th>
                  <th>{t("download_page.table.version")}</th>
                  <th>{t("download_page.table.platform")}</th>
                  <th>{t("download_page.table.download")}</th>
                </tr>
              </thead>
              <tbody className="download-product-list">
                {downloadData[type].map((product) => (
                  <tr key={uuid()}>
                    <td>
                      <Link to={`/${product.product_slug}`}>
                        <div className="download-product-name">
                          <img
                            className="product-download-image"
                            src={`${process.env.REACT_APP_BACKEND_URL}/media/${product.product_icon}`}
                            alt=""
                          />
                          <span>{product.product_name}</span>
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Link to={`/${product.product_slug}`}>
                        {product.product_title}
                      </Link>
                    </td>
                    <td>
                      <Link to={`/${product.product_slug}`}>
                        {product.current_version}
                      </Link>
                    </td>
                    <td>
                      <Link to={`/${product.product_slug}`}>
                        <div className="download-version">
                          <span>
                            {product.supporting_os
                              ?.split(" ")
                              ?.map((word) =>
                                word === osVersion ? (
                                  <strong key={uuid()}>{` ${word}`}</strong>
                                ) : (
                                  <span key={uuid()}>{` ${word}`}</span>
                                ),
                              )}
                          </span>
                          <span>{product.product_server}</span>
                        </div>
                      </Link>
                    </td>
                    <td>
                      <a
                        className="product-download-button"
                        href={product.download_link}
                      >
                        <MdOutlineFileDownload className="downloadpage-icon" />
                        {t("download_page.table.download")}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

export default DownloadTable;
