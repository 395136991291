import IPDetails from "./IPDetails";

export const AddLangToPath = (path) => {
  const lang = window.location.pathname.split("/")[1];
  const supportedLanguages = ["fr", "de", "es", "pt", "it"];

  if (lang && supportedLanguages.includes(lang)) {
    return `/${lang}${path}`;
  } else {
    if (!window.localStorage.getItem("lang")) {
      IPDetails().then((data) => {
        try {
          const ipLang = data.country_code.toLowerCase();

          const langExists = supportedLanguages === ipLang;
          if (langExists) {
            window.localStorage.setItem("lang", ipLang);
            return path;
          } else {
            window.location.href = "/";
            window.localStorage.setItem("lang", "en");
            return path;
          }
        } catch (e) {
          console.log(e);
        }
      });
    }
    return path;
  }
};
