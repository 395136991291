import React, { useEffect, useState } from "react";
import "../../static/css/resources/articles.css";
import { Link } from "react-router-dom";
import { AddLangToPath } from "../../helper/AddLanguagePath";
import { commonAPI } from "../../api/commonAPI";
import { useTranslation } from "react-i18next";

const Articles = () => {
  const { t } = useTranslation();
  document.title = t("resources.articles.title");
  const [articleData, setArticleData] = useState();

  useEffect(() => {
    commonAPI("allarticles").then((result) => setArticleData(result));
  }, []);

  return (
    <>
      <div className="header-background">
        <div className="articles-header">
          <h1>{t("resources.articles.title")}</h1>
          <p>
            {t("resources.articles.description")}{" "}
            <Link to={AddLangToPath("/product-support/")}>
              {t("resources.articles.contact")}
            </Link>
            .
          </p>
        </div>
      </div>
      <article className="articles">
        {articleData?.map((item) => {
          const productDetails = Object.values(item)[0];
          return (
            <>
              {Object.keys(productDetails.article).length > 0 ? (
                <section>
                  <div className="article-title">
                    <h3>{productDetails.product_name}</h3>
                  </div>
                  <div className="article-list">
                    <ul>
                      {Object.keys(productDetails.article).map(
                        (article_slug, index) => {
                          return (
                            <li>
                              {index + 1}.
                              <Link
                                key={article_slug}
                                to={AddLangToPath(
                                  `/${Object.keys(item)[0]}/${article_slug}`,
                                )}
                                target="_blank"
                              >
                                <p>
                                  {
                                    productDetails.article[article_slug]
                                      .article_title
                                  }
                                </p>
                              </Link>
                            </li>
                          );
                        },
                      )}
                    </ul>
                  </div>
                </section>
              ) : null}
            </>
          );
        })}
      </article>
    </>
  );
};

export default Articles;
