import React from 'react'
import PropTypes from 'prop-types';
import '.././static/css/thankYou.css'
import { useTranslation } from 'react-i18next';
import { AddLangToPath } from '../helper/AddLanguagePath';

const ThankYou = ({ applicationName }) => {
  const { t } = useTranslation();
  return (
    <div className="contactus-success">
      <div className="success-page">
        <div className="success-message">
          <h3>{t("thank_you.title")}</h3>
          <p>
            {t("thank_you.description1")}{" "}
            {applicationName ? (
              <>
                {t("thank_you.description2")}{" "}
                <b style={{ color: "#3498db" }}>{applicationName}</b>
              </>
            ) : (
              ""
            )}{" "}
            {t("thank_you.description3")}
          </p>
          <p>{t("thank_you.description4")}</p>
        </div>
        <button type="button">
          <a href={AddLangToPath("/")}>Return to Home Page</a>
        </button>
      </div>
    </div>
  );
};
ThankYou.propTypes = {
  applicationName: PropTypes.string
};

export default ThankYou
