import React, { useEffect } from "react";
import "../static/css/downloadPage/downloadPage.css";
import DownloadTitle from "../components/downloadPage/DownloadTitle";
import DownloadTable from "../components/downloadPage/DownloadTable";

const DownloadPage = () => {
  document.title = "Download Deskshare Products";
  function getDeviceType(userAgent) {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|Touch/i.test(
      userAgent,
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!getDeviceType(navigator.userAgent)) {
    return (
      <div className="download-page">
        <DownloadTitle />
        <DownloadTable />
      </div>
    );
  } else {
    window.location.href = "/";
  }
};

export default DownloadPage;
