import axios from "axios";

export default async function CurrencyConverter() {
  const defaultCurrency = {
    currency_code: "USD",
    currency_symbol: "$",
    exchange_rate: 1,
  };

  let storedData =
    JSON.parse(localStorage.getItem("currencyData")) || defaultCurrency;
  let storedTimestamp = localStorage.getItem("currencyTimestamp");
  let currentTimestamp = new Date().getTime();

  if (storedData && currentTimestamp - storedTimestamp < 24 * 60 * 60 * 1000) {
    // Do nothing
  } else {
    try {
      const response = await axios.get(process.env.REACT_APP_CURRENCY_URL);
      if (response.data.status === "success") {
        const data = response.data;
        localStorage.setItem(
          "currencyData",
          JSON.stringify({
            currency_code: data.currency_code,
            currency_symbol: data.currency_symbol,
            exchange_rate: data.exchange_rate,
          }),
        );
        localStorage.setItem("currencyTimestamp", currentTimestamp.toString());
      } else {
        localStorage.setItem("currencyData", JSON.stringify(defaultCurrency));
        localStorage.setItem("currencyTimestamp", currentTimestamp.toString());
      }
    } catch (e) {
      localStorage.setItem("currencyData", JSON.stringify(defaultCurrency));
      localStorage.setItem("currencyTimestamp", currentTimestamp.toString());
    }
  }
}
