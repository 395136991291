import React, { useEffect, useState } from "react";
import ".././static/css/reviews.css";
import { Link, useParams } from "react-router-dom";
import { fetchData } from "../api/productPageAPI";
import { useTranslation } from "react-i18next";

const Reviews = () => {
  const { t,i18n } = useTranslation();
  let { productSlug } = useParams();
  const priceValue = JSON.parse(localStorage.getItem("currencyData"));
  const [review, setReview] = useState(null);
  const [productPrice, setProductPrice] = useState(null);
  const currencyData  = JSON.parse(localStorage.getItem("currencyData"));

  const { currency_code, currency_symbol, exchange_rate } =
  currencyData || {};

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData(`${productSlug}/review`).then((result) => setReview(result));
    fetchData(`${productSlug}/cart`).then((result) =>
      setProductPrice(result[0]?.catagory_price),
    );
  }, [productSlug]);
  const currentLang = i18n.language || "en";
  const purchaseUrl = currentLang === "en" 
    ? `/${productSlug}/purchase` 
    : `/${currentLang}/${productSlug}/purchase`;

  if (review) {
    return (
      <div className="review-page">
        <div className="reviews-header">
          <div className="review-box">
            <div className="review-header-text">
              <h1>
                <Link to={`/${productSlug}`}>{review[0].product_name}</Link>
              </h1>
              <p>{review[0].product_title}</p>
            </div>
            <div className="buy-trial">
              <div className="free">
                <Link to={review[0]?.download_link}>
                  <button>
                    <div className="button-text">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="far"
                        data-icon="circle-down"
                        height="15px"
                        width="15px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M256 464a208 208 0 1 1 0-416 208 208 0 1 1 0 416zM256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM376.9 294.6c4.5-4.2 7.1-10.1 7.1-16.3c0-12.3-10-22.3-22.3-22.3H304V160c0-17.7-14.3-32-32-32l-32 0c-17.7 0-32 14.3-32 32v96H150.3C138 256 128 266 128 278.3c0 6.2 2.6 12.1 7.1 16.3l107.1 99.9c3.8 3.5 8.7 5.5 13.8 5.5s10.1-2 13.8-5.5l107.1-99.9z"
                        ></path>
                      </svg>
                      <span>
                        {productPrice === undefined ||
                        review[0]?.product_name === "Document Translator"
                          ? `Download`
                          : t("reviews.free")}
                      </span>
                    </div>
                  </button>
                  <p>
                    <svg
                      stroke="#000000a8"
                      fill="#000000a8"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      height="15px"
                      width="15px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="50"
                        d="M336 176 225.2 304 176 255.8"
                      ></path>
                      <path
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="50"
                        d="M463.1 112.37C373.68 96.33 336.71 84.45 256 48c-80.71 36.45-117.68 48.33-207.1 64.37C32.7 369.13 240.58 457.79 256 464c15.42-6.21 223.3-94.87 207.1-351.63z"
                      ></path>
                    </svg>
                    <b>{t("reviews.download")}</b>
                  </p>
                </Link>
              </div>
              <div className="buy">
                {productPrice ? (
                  <Link to={purchaseUrl}>
                    <button>
                      <div className="button-text">
                        <span>{t("reviews.buy")}</span>
                      </div>
                    </button>
                    <p>
                      <b>
                        {t("reviews.price")} {currency_symbol}
                        {parseFloat(productPrice * exchange_rate).toFixed(
                          2,
                        )}{" "}
                        {currency_code}
                      </b>
                    </p>
                  </Link>
                ) : (
                  <Link to={`/${productSlug}/purchase`}>
                    <button>
                      <div className="button-text">
                        <span>{t("reviews.learn")}</span>
                      </div>
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="review-container">
          <h3 className="review-heading">
            {review[0].product_name} {t("reviews.title")}
          </h3>
          {review.map((review) => {
            return (
              <div key={review.id} className="review-block">
                {review.review_title ? (
                  <h4 className="review-title">
                    &ldquo;{review.review_title}&rdquo;
                  </h4>
                ) : (
                  <h4 className="review-title"> </h4>
                )}
                <p className="review">{review.review}</p>
                <p className="reviewer">
                  <b>- </b>
                  {review.reviewer_name}
                </p>
              </div>
            );
          })}
          <Link to={`/${productSlug}`}>
            <div className="next-page">
              <div>
                <h4 className="next-title">{t("reviews.next")}</h4>
                <p className="read-more">
                  {t("reviews.read")} {review[0].product_name}
                </p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="next-page-icon"
                viewBox="0 0 512 512"
                width={"10px"}
                height={"10px"}
              >
                <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" />
              </svg>
            </div>
          </Link>
        </div>
      </div>
    );
  }
};

export default Reviews;
